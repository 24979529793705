import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { MainWrapperRoutingModule } from './main-wrapper-routing.module';
import { PartialsModule } from 'src/app/partials/partials.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    MainWrapperRoutingModule,
    PartialsModule,
    MatSnackBarModule,
  ],
  declarations: [MainWrapperComponent],
})
export class MainWrapperModule {}
