import {Component, OnDestroy, OnInit} from '@angular/core';
import {MyProfileInterface} from '../../../models/my-profile.model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DataService} from '../../../services/data.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit, OnDestroy {
  private userDataClone: MyProfileInterface;
  private destroySubject$ = new Subject();

  constructor(
    private _dataService: DataService,
    private _translateService: TranslateService,
    private _clipboard: Clipboard,
    private _snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.initializeValues();
  }

  private initializeValues(): void {
    if (localStorage.getItem('jwtToken')) {
      this._dataService.getUserProfile()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((res) => {
          this.userDataClone = res;
        });
    }
  }

  public copyToClipboard(value: number): void {
    this._clipboard.copy(value.toString());
    this._snackBar.open(
      this._translateService.instant('PROFILE.PROFILE.COPIED_SUCCESSFULLY'),
      '',
      { duration: 1000 }
    );
  }

  public getUserId(): number {
    return this.userDataClone?.id;
  }

  public maskEmail(): string {
    const email = this.userDataClone?.email;

    if (!email || typeof email !== 'string') {
      return '';
    }

    const [localPart, domain] = email.split('@');
    if (!domain || localPart.length <= 3) {
      return email;
    }

    const visiblePart = localPart.slice(0, 3);
    const maskedPart = '*'.repeat(localPart.length - 3);

    return `${visiblePart}${maskedPart}@${domain}`;
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
