import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from './pages/search/search.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { AuthGuard } from './services/auth.guard';
import { DataService } from './services/data.service';
import { SetLandingLanguageComponent } from './pages/landing/set-landing-language/set-landing-language.component';
import { FeesAndLimitsComponent } from './pages/fees-and-limits/fees-and-limits.component';
import { PublicApiComponent } from './pages/public-api/public-api.component';
import { WalletStatus } from './pages/wallet-status/wallet-status.component';
import { RedirectGuard } from './services/redirectGuard';
import { MainWrapperComponent } from './pages/main-wrapper/main-wrapper/main-wrapper.component';
import { ChartComponent } from './pages/markets/chart/chart.component';
import { WithdrawComponent } from './pages/profile/withdraw/withdraw.component';
import { HistoryComponent } from './pages/profile/history/history.component';
import { LandingComponent } from './pages/landing/landing.component';
import { CryptoCredentialComponent } from './pages/crypto-credential/crypto-credential.component';
// import {CryptoCredentialSendingComponent} from './pages/crypto-credential-sending/crypto-credential-sending.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ReceiveAuthTokenComponent } from './pages/receive-auth-token/receive-auth-token.component';
import { environment } from '../environments/environment';
import { BuyTDBonusesComponent } from './pages/buy-td-bonuses/buy-td-bonuses.component';
import { AltynBankComponent } from './pages/altyn-bank/altyn-bank.component';
import { PaymentStatusComponent } from './dialogs/payment-status/payment-status.component';
// import {PreventNavigationGuard} from './services/prevent-navigation.guard';
import { CryptoCardPageComponent } from './pages/crypto-card/crypto-card-page/crypto-card-page.component';
import { CryptoCredentialModule } from './pages/crypto-credential/crypto-credential.module';
import { TechnodomBonusesComponent } from './pages/technodom-bonuses/technodom-bonuses.component';

const landingHosts = new Set(['test-landing.intebix.kz', 'intebix.kz']);
const ssoHosts = new Set(['test-sso.intebix.kz', 'sso.intebix.kz']);
const localPorts = new Set(['4200', '4201', '4202']);

const isLocalHost: boolean = localPorts.has(window.location.port);
const isLandingHost: boolean =
  landingHosts.has(window.location.hostname) || window.location.port === '4201';
const isSsoHost: boolean =
  ssoHosts.has(window.location.hostname) || window.location.port === '4202';

const ssoRedirectUrl = isLocalHost
  ? `http://${window.location.hostname}:4202/sso?origin=${JSON.stringify(
      window.location.origin
    )}`
  : `${environment.ssoPage}/sso?origin=${JSON.stringify(
      window.location.origin
    )}`;

const tradeHost = isLocalHost
  ? `http://${window.location.hostname}:4200/`
  : environment.tradePage;
const homePage = isLocalHost
  ? `http://${window.location.hostname}:4200/landing`
  : environment.homepage;

const routes: Routes = [
  {
    path: 'altyn-bank',
    component: AltynBankComponent,
  },
  // {
  //   path: 'crypto-credential',
  //   component: CryptoCredentialComponent,
  // },
  { path: 'altyn-bank', component: PaymentStatusComponent, outlet: 'status' }, // TODO: выше есть еще один путь
  {
    path: 'trading-frame',
    component: ChartComponent,
    data: {
      noControls: true,
    },
  },
  // {
  //   path: '',
  //   component: MainWrapperComponent,
  //   children: mainRoutes,
  // },
  {
    path: '',
    loadChildren: () =>
      import('./pages/main-wrapper/main-wrapper.module').then(
        (m) => m.MainWrapperModule
      ),
  },
  { path: 'payment-status-ok', component: AuthPageComponent, outlet: 'popup' },
  {
    path: 'payment-status-error',
    component: AuthPageComponent,
    outlet: 'popup',
  },
  { path: 'login', component: AuthPageComponent, outlet: 'popup' },
  { path: 'sign-up', component: AuthPageComponent, outlet: 'popup' },
  { path: 'restore-password', component: AuthPageComponent, outlet: 'popup' },
  { path: 'login-verify', component: AuthPageComponent, outlet: 'popup' },
  {
    path: 'crypto-credential-sign-up',
    component: AuthPageComponent,
    outlet: 'popup',
  },
];

const routes_landing: Routes = [
  {
    path: 'markets',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: tradeHost,
    },
  },
  // {
  //   path: 'sso',
  //   canActivate: [RedirectGuard],
  //   component: RedirectGuard,
  //   data: {
  //     externalUrl: ssoRedirectUrl,
  //   },
  // },
  // {path: 'receive-token/:token', component: ReceiveAuthTokenComponent},
  // {path: 'login', component: AuthPageComponent, outlet: 'popup'},
  // {path: 'sign-up', component: AuthPageComponent, outlet: 'popup'},
  // {path: 'restore-password', component: AuthPageComponent, outlet: 'popup'},
  // {path: 'login-verify', component: AuthPageComponent, outlet: 'popup'},
  // {
  //   path: 'buy-crypto',
  //   canActivate: [RedirectGuard],
  //   component: BuyCryptoComponent,
  //   data: {
  //     externalUrl: tradeHost,
  //   },
  // },
  {
    path: 'buy-crypto',
    loadChildren: () =>
      import('./pages/buy-crypto/buy-crypto.module').then(
        (m) => m.BuyCryptoModule
      ),
    canActivate: [RedirectGuard],
    data: {
      externalUrl: tradeHost,
    },
  },
  {
    path: 'buy-td-bonuses',
    canActivate: [RedirectGuard],
    component: BuyTDBonusesComponent,
    data: {
      externalUrl: tradeHost,
    },
  },
  // {
  //   path: 'user-settings', canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: 'wallets',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/wallets`
  //       }
  //     },
  //     {
  //       path: 'my-profile',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/my-profile`
  //       }
  //     },
  //     {
  //       path: 'advcash',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/advcash`
  //       }
  //     },
  //     {
  //       path: 'bcxg',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/bcxg`
  //       }
  //     },
  //     {
  //       path: 'identity-verification',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/identity-verification`
  //       }
  //     },
  //     {
  //       path: 'my-activity',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/my-activity`
  //       }
  //     },
  //     {
  //       path: 'password-settings',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/password-settings`
  //       }
  //     },
  //     {
  //       path: 'two-factor-auth-settings',
  //       canActivate: [RedirectGuard],
  //       component: RedirectGuard,
  //       data: {
  //         externalUrl: `${tradeHost}user-settings/two-factor-auth-settings`
  //       }
  //     }
  //   ]
  // },
  {
    path: '',
    component: MainWrapperComponent,
    children: [
      { path: '', component: LandingComponent },
      // {
      //   path: 'auth',
      //   loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
      // },
      {
        path: 'auth',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'crypto-card',
        loadChildren: () =>
          import('./pages/crypto-card/crypto-card.module').then(
            (m) => m.CryptoCardPageModule
          ),
      },
      { path: 'about-us', component: AboutUsComponent },
      // {path: 'crypto-credential', component: CryptoCredentialComponent},
      // {path: 'crypto-credential-sending', component: CryptoCredentialSendingComponent},
      {
        path: 'crypto-credential-sending',
        loadChildren: () =>
          import(
            './pages/crypto-credential-sending/crypto-credential-sending.module'
          ).then((m) => m.CryptoCredentialSendingModule),
      },
      { path: 'faq', component: FaqComponent },
      { path: '**', redirectTo: '/' },
    ],
  },
];

const sso_route: Routes = [{ path: '**', component: AuthPageComponent }];

const resolveRoute = () => {
  // if (isSsoHost) {
  //   return sso_route;
  // } else {
  if (isLocalHost) {
    return routes;
  }
  if (isLandingHost) {
    return routes_landing;
  } else {
    return routes;
  }
  // }
};

@NgModule({
  imports: [RouterModule.forRoot(resolveRoute())],
  exports: [RouterModule],
  providers: [DataService],
})
export class AppRoutingModule {}
