import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  constructor(private _base: BaseService) {}


  public addPhoneNumber(data: Record<string, string>): Observable<any>  {
    return this._base.post('api/v1/otp/send', data);
  }

  public verifyPhoneNumber(data: Record<string, string>): Observable<any> {
    return this._base.post('api/v1/otp/verify', data);
  }
}
