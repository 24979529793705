import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { concat } from 'rxjs/internal/observable/concat';
import {map, tap, throttleTime} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../environments/environment';
import {io} from 'socket.io-client';
import {MarketPair} from '../interfaces/market-pair';

@Injectable()
export class LandingService {

  private _marketApi = `${environment.host}api/v1/settings/markets`;
  private socket: any;
  private _lastMarketsDetails = new Map<string, any>();
  private marketAllSubscription: (() => void) | null = null;

  // TODO: не используется
  marketsDetails$ = concat(
    this.getMarketList(),
    this.subscribeToSummaryDeltas(),
    // this._dataService.subscribeToMarketUpdate(), // TODO: сервис не должен зависеть от других сервисов
  ).pipe(
    map(
      (markets) => markets.map(
        (v) => {
          return this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName), v);
        },
      )
    ),
    tap((markets) => this._lastMarketsDetails = new Map<string, any>(markets.map((v) => [v.MarketName, v]))),
  );

  constructor(private _httpClient: HttpClient) {
    this.socket = io(environment.host);
  }

  private _mergeMarketField = (last: any, current: any) => {
    const mergedKeys = new Set([...Object.keys(last || {}), ...Object.keys(current)]);
    return {...Array.from(mergedKeys.values()).reduce(
        (acc, k) => ({...acc, [k]: current[k] || (last || {})[k]}), {}
      ),
      lastUp: last
        ? (+last.Last < +current.Last || (+last.lastUp && +last.Last === +current.Last))
        : +current.Last > +current.prevPrice,
      lastDown: last
        ? (+last.Last > +current.Last || (+last.lastDown && +last.Last === +current.Last))
        : +current.Last < +current.prevPrice,
      BaseVolumeUSDT: current.MarketName.split('-')[0] === 'USDT' ? current.BaseVolume24 : current.BaseVolumeUSDT,
    };
  }

  getMarketList(): Observable<MarketPair[]> {
    return this._httpClient.get<MarketPair[]>(this._marketApi);
  }

  public joinMarketAllRoom(): void {
    const room = 'detail:ALL';

    this.socket.emit('joinRoom', { room });
    this.socket.emit('subscribe', { symbol: 'market.all.detail', baseAsset: 'all', quoteAsset: 'all' });
  }

  public leaveMarketAllRoom(): void {
    this.socket.emit('leaveRoom', { room: 'detail:ALL' });
    this.socket.emit('unsubscribe', { symbol: 'market.all.detail', baseAsset: 'all', quoteAsset: 'all' });
  }

  subscribeToSummaryDeltas(): Observable<any> {
    return new Observable<any>((observer) => {
      const handler = (data) => observer.next(data);
      this.socket.on('market-update', handler);

      this.marketAllSubscription?.();
      this.marketAllSubscription = () => this.socket.off('market-update', handler);

      return this.marketAllSubscription;
    }).pipe(throttleTime(1000));
  }
}
