import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SvgService } from '../../services/svg.service';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {throwError} from 'rxjs/internal/observable/throwError';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'app-two-factor-auth-modal',
  templateUrl: './two-factor-auth-modal.component.html',
  styleUrls: ['./two-factor-auth-modal.component.scss']
})
export class TwoFactorAuthModalComponent implements OnInit, OnDestroy {
  public twoFaCode = '';
  public isLightTheme = false;
  public isMobile:boolean;
  public googleAuthLink:string;
  otpArray = Array(6).fill(null);
  otpValues: string[] = new Array(6).fill('');
  destroySubject$: Subject<void> = new Subject();
  otpERROR:boolean = false;
  constructor(
    private matDialogRef: MatDialogRef<TwoFactorAuthModalComponent>,
    public dataService: DataService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public svgService: SvgService) {

    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }
  ngOnInit() {
    this.isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    this.googleAuthLink = this.isMobile ? 'https://onelink.to/r252xp' : 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru'
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public close(): void {
    this.matDialogRef.close({code: this.twoFaCode});
  }

  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (!/^\d$/.test(value)) {
      input.value = '';
      return;
    }

    this.otpValues[index] = value;

    if (index < this.otpArray.length - 1 && value) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      nextInput?.focus();
    }

    if (this.isOtpComplete()) {
      this.submitOtp();
    }
  }

  private resetOtp(): void {
    this.otpArray.fill(null);
    this.otpValues.fill('');
  }
  onBackspace(event: KeyboardEvent, index: number): void {
    if (!this.otpValues[index] && index > 0) {
      const prevInput = (event.target as HTMLInputElement)
        .previousElementSibling as HTMLInputElement;
      prevInput?.focus();
    }
  }

  isOtpComplete(): boolean {
    return this.otpValues.every((value) => value.length === 1);
  }
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData('text').trim().slice(0, 6) || '';

    if (pastedData.length !== this.otpArray.length || !/^\d+$/.test(pastedData)) {
      return;
    }

    this.otpValues = pastedData.split('');
    this.otpValues.forEach((value, index) => {
      const input = document.querySelectorAll<HTMLInputElement>('input.otp-input')[index];
      if (input) {
        input.value = value;
      }
    });

    if (this.isOtpComplete()) {
      this.submitOtp();
    }
  }
  submitOtp(): void {
    const otp = this.otpValues.join('');
    const body = {email: this.data.email, password: this.data.password, code: otp, remember: true};

    let request$: Observable<any>;

    if (this.data?.isForLogin) {
      request$ = this.authService.login2Fa(body);
    } else if (this.data?.toggle2Fa) {
      request$ = this.authService.toggle2Fa(otp);
    } else {
      request$ = this.dataService.checkTwoFa(otp);
    }

    request$
      .pipe(
        takeUntil(this.destroySubject$),
        tap(res => this.matDialogRef.close({ ...res, code: otp })),
        catchError(err => {
          this.otpERROR = true;
          return throwError(() => new Error('OTP failed'));
        })
      )
      .subscribe();
  }
}
