import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class MobileMenuService {
  private isMenuOpen = new BehaviorSubject<boolean>(false);
  public isMenuOpen$ = this.isMenuOpen.asObservable()
  
  constructor() {
  }

  public setMenuStatus(value:boolean){
    this.isMenuOpen.next(value);
  }

  public getMenuStatus(){
    return this.isMenuOpen.value;
  }
}
