import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientJsonpModule,
  HttpClient,
} from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// import {CryptoCredentialComponent} from './pages/crypto-credential/crypto-credential.component';
// import { LogInComponent } from './dialogs/log-in/log-in.component';
import { DataService } from './services/data.service';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { RequestInterceptor } from './services/http_interceptor';
import { SvgService } from './services/svg.service';
import { ValidationService } from './services/validation.service';
// import { ImageLoaderComponent } from './pages/profile/identity-verification/image-loader/image-loader.component';
// import { GeneralInfoComponent } from './pages/profile/identity-verification/general-info/general-info.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AppConfig } from './app.config';
import { TitleService } from './services/title.service';
import { RedirectGuard } from './services/redirectGuard';
import { NgxMaskModule } from 'ngx-mask';
import { MobxAngularModule } from 'mobx-angular';
import { LandingService } from './services/landing.service';
import { ThemeService } from './services/theme.service';
import { SharedModule } from './shared/shared.module';
import { DomainService } from './services/domain.service';
import {
  ServiceWorkerModule,
  SwRegistrationOptions,
} from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { CookiesService } from './services/cookies.service';
import { AlertModule } from './dialogs/alert/alert.module';
import { MainWrapperModule } from './pages/main-wrapper/main-wrapper.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.4,
};

export function initializeDomainService(service: DomainService): () => void {
  return () => service.handleDomainChange();
}

@NgModule({
  declarations: [
    AppComponent,
    // CryptoCredentialComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    MobxAngularModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    AlertModule,
    MainWrapperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: AppConfig.httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DataService,
    SvgService,
    ValidationService,
    TitleService,
    RedirectGuard,
    CookiesService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfig.init,
      deps: [TranslateService, CookiesService],
      multi: true,
    },
    DomainService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDomainService,
      deps: [DomainService, TranslateService, CookiesService],
      multi: true,
    },
    LandingService,
    ThemeService,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({
        enabled: environment.production,
        registrationStrategy: 'registerImmediately',
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
