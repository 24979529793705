import { Component } from '@angular/core';

@Component({
  selector: 'bix-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  HtmlAccountDeactivated = 'accountDeactivatedA';
}
