import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { IShortEntity } from '../../interfaces/shortEntity.interface';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-multiselect',
  templateUrl: './new-multiselect.component.html',
  styleUrls: ['./new-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewMultiselectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() selectedLabelProps: string = 'Выбрано';
  @Input() optionsProps: IShortEntity[] = [];
  @Input() disabledProps: boolean = false;
  @Input() boldLabelProps: boolean = false;
  @Input() withSearchProps: boolean = false;
  @Input() formControlProps: FormControl;

  @Output() optionSelected = new EventEmitter<IShortEntity[]>();

  private destroySubject$: Subject<void> = new Subject();
  public searchControl = new FormControl('');
  public isActive: boolean = false;
  public selectedOptions: IShortEntity[] = [];
  public cloneOptions: IShortEntity[];
  private globalClickUnlistener: () => void;
  private subscriptions: Subscription = new Subscription();

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this.initializeValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionsProps && changes.optionsProps.currentValue) {
      this.cloneOptions = [...this.optionsProps];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.detachGlobalClickListener();
  }

  private initializeValues(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroySubject$)
      )
      .subscribe((searchText) => {
        this.performSearch(searchText || '');
      });

    this.selectedOptions = this.formControlProps?.value || null;

    if (this.formControlProps) {
      const controlSub = this.formControlProps.valueChanges.subscribe(
        (value) => {
          this.selectedOptions = value;
        }
      );
      this.subscriptions.add(controlSub);
    }
  }

  public getSelectedOptionsLabel(): string {
    // return this.selectedOptions?.map((option) => option.label).join(', ') || '';
    return this.selectedOptions?.length === 1
      ? this.selectedOptions[0].label
      : `${this.selectedLabelProps} (${this.selectedOptions?.length})`;
  }

  toggleDropdown(): void {
    if (!this.disabledProps) {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.attachGlobalClickListener();
      } else {
        this.detachGlobalClickListener();
      }
    }
  }

  isSelected(option: IShortEntity): boolean {
    return this.selectedOptions?.some(
      (selected) => selected.value === option.value
    );
  }

  toggleOption(option: IShortEntity): void {
    this.selectedOptions = this.selectedOptions || [];

    const index = this.selectedOptions.findIndex(
      (selected) => selected.id === option.id
    );

    if (index === -1) {
      this.selectedOptions = [...this.selectedOptions, option];
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (selected) => selected.id !== option.id
      );
    }

    this.formControlProps?.setValue([...this.selectedOptions]);
    this.optionSelected.emit([...this.selectedOptions]);
  }

  private attachGlobalClickListener(): void {
    this.globalClickUnlistener = this._renderer.listen(
      'document',
      'click',
      (event: MouseEvent) => this.onGlobalClick(event)
    );
  }

  private detachGlobalClickListener(): void {
    if (this.globalClickUnlistener) {
      this.globalClickUnlistener();
      this.globalClickUnlistener = null;
    }
  }

  private onGlobalClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!this._elementRef.nativeElement.contains(target)) {
      this.isActive = false;
      this.detachGlobalClickListener();
    }
  }

  private performSearch(searchText: string): void {
    if (!searchText) {
      this.cloneOptions = [...this.optionsProps];
    } else {
      this.cloneOptions = this.optionsProps.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  }
}
