import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})

export class PrimaryButtonComponent {
  @Input() isLoading: boolean = false;
  @Input() buttonText: string = '';
  @Input() routerLinkPath: string = '';
  @Input() isDisabled: boolean = false;
  @Input() maxWidth: string | number = '100%';
  @Input() maxHeight: string | number = '100%';
  @Input() additionalButtonText = '';
  @Output() buttonClick = new EventEmitter<void>();

  constructor(private router: Router) {}

  onClick(event: Event) {
    if (!this.isDisabled && !this.isLoading) {
      this.buttonClick.emit();
    }
  }
}
