import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-prize-modal',
  templateUrl: './prize-modal.component.html',
  styleUrls: ['./prize-modal.component.scss']
})

export class PrizeModalComponent {
  constructor(
    private _matDialogRef: MatDialogRef<PrizeModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  public prize: '1%' | '500K' | '3%' | '0' | '10K-3hours' | '250K' |
    '5%' | '100K' | '100K-3hours' | '50K' | '50K-3hours' | '5min-infinity-energy' = this.data.prize;

  closeModal() {
    this._matDialogRef.close();
  }
}
