import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IShortEntity } from '../../interfaces/shortEntity.interface';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent implements OnInit {
  @Input() options: IShortEntity[] = [];
  @Input() selectedOption: IShortEntity;
  @Output() optionChanged: EventEmitter<IShortEntity> =
    new EventEmitter<IShortEntity>();

  ngOnInit(): void {
    if (!this.selectedOption && this.options.length) {
      this.selectedOption = this.options[0];
    }
  }

  public selectOption(option: IShortEntity): void {
    if (this.selectedOption !== option) {
      this.selectedOption = option;
      this.optionChanged.emit(option);
    }
  }
}
