import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainWrapperComponent } from './main-wrapper/main-wrapper.component';
import { RedirectGuard } from 'src/app/services/redirectGuard';
import { environment } from 'src/environments/environment';
import { AuthGuard } from 'src/app/services/auth.guard';

const localPorts = new Set(['4200', '4201', '4202']);
const isLocalHost: boolean = localPorts.has(window.location.port);

const ssoRedirectUrl = isLocalHost
  ? `http://${window.location.hostname}:4202/sso?origin=${JSON.stringify(
      window.location.origin
    )}`
  : `${environment.ssoPage}/sso?origin=${JSON.stringify(
      window.location.origin
    )}`;
const homePage = isLocalHost
  ? `http://${window.location.hostname}:4200/landing`
  : environment.homepage;

const mainRoutes: Routes = [
  {
    path: 'crypto-credential-sending',
    loadChildren: () =>
      import(
        '../crypto-credential-sending/crypto-credential-sending.module'
      ).then((m) => m.CryptoCredentialSendingModule),
  },
  {
    path: 'crypto-card',
    loadChildren: () =>
      import('../crypto-card/crypto-card.module').then(
        (m) => m.CryptoCardPageModule
      ),
  },
  {
    path: 'crypto-credential',
    loadChildren: () =>
      import('../crypto-credential/crypto-credential.module').then(
        (m) => m.CryptoCredentialModule
      ),
  },
  {
    path: 'altyn-bank',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'receive-token/:token',
    loadChildren: () =>
      import('../receive-auth-token/receive-auth-token.module').then(
        (m) => m.ReceiveAuthTokenModule
      ),
  },
  {
    path: 'sso',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: ssoRedirectUrl,
    },
  },
  { path: '', pathMatch: 'full', redirectTo: '/markets' },
  {
    path: 'home',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: homePage,
    },
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('../landing/landing.module').then((m) => m.LandingModule),
  },
  // {path: 'crypto-credential-sending', component: CryptoCredentialSendingComponent},
  {
    path: 'about-us',
    loadChildren: () =>
      import('../about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../faq/faq.module').then((m) => m.FaqModule),
  },
  { path: 'fees-and-limits', loadChildren: () => import('../fees-and-limits/fees-and-limits.module').then(m => m.FeesAndLimitsModule) },
  { path: 'wallet-status', loadChildren: () => import('../wallet-status/wallet-status.module').then(m => m.WalletStatusModule) },
  { path: 'public-api', loadChildren: () => import('../public-api/public-api.module').then(m => m.PublicApiModule) },
  // { path: 'sign-up/:lang', component: SetLandingLanguageComponent }, // /langing/sign-up/:lang
  // { path: 'search/:lang', component: SetLandingLanguageComponent }, // /langing/search/:lang
  // { path: 'login/:lang', component: SetLandingLanguageComponent }, // /langing/login/:lang
  { path: 'markets', loadChildren: () => import('../search/search.module').then(m => m.SearchModule) },
  {
    path: 'restore-password/:token',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'change-password/:token',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'login-verify/:token',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'validate-email/:token',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'payment/success',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'payment/failure',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'payment/cancellation',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  // {path: 'payment/notification', loadChildren: () =>
  // import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),},
  {
    path: 'payment/receipt-unverified',
    loadChildren: () =>
      import('../auth-page/auth-page.module').then((m) => m.AuthPageModule),
  },
  // { path: 'user-kyc/approve/:token', component: KycPageComponent },
  // { path: 'user-kyc/reject/:token', component: KycPageComponent },
  // { path: 'user-kyc/block/:token', component: KycPageComponent },
  {
    path: 'user-kyc',
    loadChildren: () =>
      import('../kyc-page/kyc-page.module').then((m) => m.KycPageModule),
  },
  {
    path: 'trades',
    loadChildren: () =>
      import('../markets/markets.module').then((m) => m.MarketsModule),
  },
  {
    path: 'buy-crypto',
    loadChildren: () =>
      import('../buy-crypto/buy-crypto.module').then((m) => m.BuyCryptoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'buy-td-bonuses',
    loadChildren: () =>
      import('../buy-td-bonuses/buy-td-bonuses.module').then(
        (m) => m.BuyTdBonusesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/about',
    },
  },
  {
    path: 'docs/ippolicy',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl:
        'https://www.biteeu.com/docs/BITEEU IP Address  and Cookie Policy SITE.pdf',
    },
  },
  {
    path: 'docs/privacyestonia',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl:
        'https://www.biteeu.com/docs/Biteeu privacy Estonia SITE.pdf',
    },
  },
  {
    path: 'docs/termsofuseestonia',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/docs/Terms of Use Estonia SITE.pdf',
    },
  },
  {
    path: 'faq',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.biteeu.com/faq',
    },
  },
  {
    path: 'twitter',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://twitter.com/BiteeuExchange',
    },
  },
  {
    path: 'instagram',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl:
        'https://instagram.com/biteeu_exchange?utm_source=ig_profile_share&igshid=1jxb6yl09mmea',
    },
  },
  {
    path: 'linkedin',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.linkedin.com/company/biteeu/?viewAsMember=true',
    },
  },
  {
    path: 'telegram',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://t.me/biteeu_chat',
    },
  },
  // {path: 'search', component: SearchComponent},
  {
    path: 'admin-intebix',
    loadChildren: () =>
      import('../../admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'user-settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'fiat-transactions',
    loadChildren: () =>
      import('../fiat-transactions/fiat-transactions.module').then(
        (m) => m.FiatTransactionsModule
      ),
  },
  {
    path: 'wallets',
    loadChildren: () =>
      import('../wallets/wallets.module').then((m) => m.WalletsModule),
  },
  {
    path: 'p2p',
    loadChildren: () => import('../p2p/p2p.module').then((m) => m.P2PModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'technodom-bonuses',
    loadChildren: () =>
      import('../technodom-bonuses/technodom-bonuses.module').then(
        (m) => m.TechnodomBonusesModule
      ),
  },
  // {
  //   path: 'login',
  //   loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
  // },
  // {
  //   path: 'user-settings', canActivate: [AuthGuard],
  //   children: [
  //     {path: 'history', component: HistoryComponent},
  //     {path: 'deposit', component: DepositComponent},
  //     {path: 'buy-crypto-by-card/:currency', component: DepositComponent},
  //     {path: 'withdraw', component: WithdrawComponent},
  //     {path: 'withdraw/:currency', component: WithdrawComponent},
  //   ]
  // },
  { path: '**', redirectTo: '/markets' },
];

const routes: Routes = [
  { path: '', component: MainWrapperComponent, children: mainRoutes },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainWrapperRoutingModule {}
