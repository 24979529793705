import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSelectComponent } from './components/new-select/new-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewInputComponent } from './components/new-input/new-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegExpDirective } from './directives/reg-exp.directive';
import { FearGreedIndexComponent } from './components/fear-greed-index/fear-greed-index.component';
import { NewSmartInputComponent } from './components/new-smart-input/new-smart-input.component';
import { TableComponent } from './components/table/table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NewDatepickerComponent } from './components/new-datepicker/new-datepicker.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { NumberOnlyDirective } from './directives/only-number.directive';
import { MatDatepickerComponent } from './components/mat-datepicker/mat-datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { DatepickerHeaderComponent } from './components/mat-datepicker/datepicker-header/datepicker-header.component';
import { RouterModule } from '@angular/router';
import { StepperComponent } from './components/stepper/stepper.component';
import { NewTextareaComponent } from './components/new-textarea/new-textarea.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { MatDateRangePickerComponent } from './components/mat-date-range-picker/mat-date-range-picker.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { NewMultiselectComponent } from './components/new-multiselect/new-multiselect.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { WheelModule } from './components/wheel/wheel.module';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OtpResponseComponent } from './components/otp-response/otp-response.component';
import { OtpPageComponent } from './components/otp-page/otp-page.component';
import { EmailChangeComponent } from './components/email-change/email-change.component';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { InputErrorTextComponent } from './components/input-error-text/input-error-text.component';

@NgModule({
  declarations: [
    SecondaryButtonComponent,
    PrimaryButtonComponent,
    NewSelectComponent,
    NewInputComponent,
    FearGreedIndexComponent,
    NewSmartInputComponent,
    EmailChangeComponent,
    OtpPageComponent,
    OtpResponseComponent,
    TableComponent,
    PaginationComponent,
    NewDatepickerComponent,
    ConfirmEmailComponent,
    MatDatepickerComponent,
    MatDateRangePickerComponent,
    DatepickerHeaderComponent,
    StepperComponent,
    NewTextareaComponent,
    SwitcherComponent,
    NewMultiselectComponent,
    UserInfoComponent,
    CustomModalComponent,

    // directives
    RegExpDirective,
    OnlyLettersDirective,
    NumberOnlyDirective,
    InputErrorTextComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    RouterModule,
    MatCheckboxModule,
    WheelModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    NewSelectComponent,
    NewInputComponent,
    FearGreedIndexComponent,
    NewSmartInputComponent,
    TableComponent,
    PaginationComponent,
    NewDatepickerComponent,
    ConfirmEmailComponent,
    MatDatepickerComponent,
    MatDateRangePickerComponent,
    StepperComponent,
    NewTextareaComponent,
    SwitcherComponent,
    NewMultiselectComponent,
    CustomModalComponent,

    InputErrorTextComponent,
    // directives
    RegExpDirective,
    OnlyLettersDirective,
    NumberOnlyDirective,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    WheelModule,
    UserInfoComponent,
  ],
})
export class SharedModule {}
