import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FiatTabService {
  private tabIndex = new BehaviorSubject<0 | 1 | 2>(this.getTabIndexFromStorage());
  public tabIndex$ = this.tabIndex.asObservable();

  constructor() {
  }

  public setTabIndex(value: 0 | 1 | 2){
    this.tabIndex.next(value);
    localStorage.setItem('tabIndex', value.toString())
  }

  public getTabIndexFromStorage(): 2 | 1 | 0 {
    const tabIndex = parseInt(localStorage.getItem('tabIndex'), 10)
    if (tabIndex === 2 || tabIndex === 1 || tabIndex === 0)
      return tabIndex
    return 0;
  }

}
