import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterViewInit, OnDestroy {
  @ViewChildren('stepElement') stepElements!: QueryList<ElementRef>;
  @ViewChild('stepper') stepperRef!: ElementRef;

  @Input() stepsProps: { label: string }[] = [];
  @Input() activeStepProps: number = 0;
  @Input() isVerticalMobileProps: boolean = false;
  @Input() lastActivateStepProps: boolean = false;

  public lineWidth: string = '0px';
  public maxStepWidth: number = 0;
  public lastInRow: boolean[] = [];

  constructor(private _cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setLineWidths();
    this.calculateMaxWidth();
    this.adjustLines();

    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setLineWidths();
    this.adjustLines();
    this._cdr.detectChanges();
  }

  private calculateMaxWidth(): void {
    this.maxStepWidth = Math.max(
      ...this.stepElements.map((step) => step.nativeElement.offsetWidth)
    );
  }

  public setLineWidths(): void {
    if (!this.stepperRef) return;

    setTimeout(() => {
      const stepperWidth = this.stepperRef.nativeElement.offsetWidth;
      const stepElementsArray = this.stepElements.toArray();
      const rowPositions: { [key: number]: ElementRef[] } = {};

      stepElementsArray.forEach((step) => {
        const topOffset = step.nativeElement.offsetTop;
        if (!rowPositions[topOffset]) {
          rowPositions[topOffset] = [];
        }
        rowPositions[topOffset].push(step);
      });

      Object.values(rowPositions).forEach((row) => {
        const stepCount = row.length;
        if (stepCount <= 1) return;

        const stepWidth = stepperWidth / stepCount;
        const lineWidth = `${stepWidth - 50}px`;

        row.forEach((step, index) => {
          const lineElement = step.nativeElement.querySelector('.line');
          if (lineElement) {
            lineElement.style.width = lineWidth;
            if (index === stepCount - 1) {
              lineElement.style.display = 'none';
            }
          }
        });
      });

      this._cdr.detectChanges();
    }, 100);
  }

  private adjustLines(): void {
    setTimeout(() => {
      if (!this.stepElements) return;

      const stepElementsArray = this.stepElements.toArray();
      const rowPositions: { [key: number]: ElementRef[] } = {};
      let prevTopOffset = -1;
      let currentRow: ElementRef[] = [];

      stepElementsArray.forEach((step, index) => {
        const topOffset = step.nativeElement.offsetTop;

        if (prevTopOffset !== -1 && topOffset !== prevTopOffset) {
          rowPositions[prevTopOffset] = [...currentRow];
          currentRow = [];
        }

        currentRow.push(step);
        prevTopOffset = topOffset;

        if (index === stepElementsArray.length - 1) {
          rowPositions[topOffset] = [...currentRow];
        }
      });

      Object.values(rowPositions).forEach((row) => {
        row.forEach((step, index) => {
          const lineElement = step.nativeElement.querySelector('.line');
          if (lineElement) {
            lineElement.style.display = index === row.length - 1 ? 'none' : 'block';
          }
        });
      });

      this._cdr.detectChanges();
    }, 100);
  }

}
