import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-fear-greed-index',
  templateUrl: './fear-greed-index.component.html',
  styleUrls: ['./fear-greed-index.component.scss'],
})
export class FearGreedIndexComponent implements OnChanges, OnInit {
  @Input() percentageProps: number;
  public isLightTheme = false;
  public headerItems: { title: string; color: string }[] = [];
  // public headerItems: Record<string, string>[] = [
  //   { title: 'MARKETS.FEAR', color: '#DA2A22' },
  //   { title: 'MARKETS.GREED', color: '#6AC332' },
  //   { title: 'MARKETS.NEUTRAL', color: '#DFA00F' },
  // ];
  private currentPercentage: number = 0;
  public percentageColor: string = '#DA2A22';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    const items = [
      { title: 'MARKETS.FEAR', color: '#DA2A22' },
      { title: 'MARKETS.GREED', color: '#6AC332' },
      { title: 'MARKETS.NEUTRAL', color: '#DFA00F' },
    ];

    this.headerItems = items.map((headerItem) => ({
      ...headerItem,
      title: this.translate.instant(headerItem.title), // Применяем перевод
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['percentageProps']) {
      this.animateNeedle();
    }
  }

 public getNeedleRotation(): string {
    const angle = -90 + (this.currentPercentage / 100) * 180;

   this.percentageColor = this.getPercentageColor();

    return `rotate(${angle}deg)`;
  }

  public getPizzaRotation(){
    const angle =  (this.currentPercentage / 100) * 180;

    this.percentageColor = this.getPercentageColor();
    return `rotate(${angle}deg)`;
  }

  private getPercentageColor(): string {
    if (this.percentageProps < 20) {
      return '#db381e';
    } else if (this.percentageProps < 40) {
      return '#e07f10';
    } else if (this.percentageProps < 60) {
      return '#df9c0f';
    } else if (this.percentageProps < 80) {
      return '#decc0d';
    } else {
      return '#65c133';
    }

  }

  private animateNeedle(): void {
    const targetPercentage = this.percentageProps;

    const interval = setInterval(() => {

      if (this.currentPercentage < targetPercentage) {
        this.currentPercentage++;
      } else if (this.currentPercentage > targetPercentage) {
        this.currentPercentage--;
      } else {
        clearInterval(interval);
      }
    }, 10);
  }
}
