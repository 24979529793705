import {Component, Input, EventEmitter, Output, OnInit, OnDestroy, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ThemeService} from '../../services/theme.service';
import {LanguageState} from '../../store/language.state';
import {NavigationEnd} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import { FiatTabService } from 'src/app/services/fiat-tab.service';
import { MobileMenuService } from 'src/app/services/mobile-menu.service';
import { Subscription } from 'rxjs';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import {LanguageTypes} from '../../shared/enums/languages.enum';
import {MatRadioChange} from '@angular/material/radio';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy, OnChanges {
  protected readonly environment = environment;
  @Input() opened: boolean;
  @Input() isProfileMenuOpened: boolean;
  @Output() navigateEvent = new EventEmitter<string>();
  @Output() closeMenuEvent = new EventEmitter<boolean>();
  @ViewChild('langSidenav') langSidenav!: MatSidenav;
  public openList = { profile: false, lang: false, account: false };
  public currentLang = this.translateService.currentLang;
  private previousLang = this.currentLang;
  public buttonIsDisabled = true;
  public isMainDomain;
  public isSubDomain;
  public isTestSubdomain;
  isLandingPage: boolean;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  events: string[] = [];
  icon: boolean = false;
  public tabSubscription:Subscription
  activeFiatTab:number = parseInt(localStorage.getItem('tabIndex')) || 0;
  public menuLinks: Record<string, any>[];
  public readonly LanguageTypes = LanguageTypes;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private translateService: TranslateService,
    public authService: AuthService,
    private router: Router,
    public themeService: ThemeService,
    public languageState: LanguageState,
    public _fiatTabsService: FiatTabService,
    private mobileMenuService: MobileMenuService,
    public dialog: MatDialog
  ) {
    this.updateMenuLinks();
  }
  ngOnInit() {
    this.isLandingPage = this.router.url.includes('/landing') || this.router.url === '/';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isLandingPage = this.router.url.includes('/landing') || this.router.url === '/';
      }
    });

    this.tabSubscription = this._fiatTabsService.tabIndex$.subscribe((res)=>{
      this.activeFiatTab = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['opened']?.currentValue === false) {
      this.closeMenu();
    }
    this.updateMenuLinks();
  }

  ngOnDestroy(): void {
    this.close();
    this.closeMenu();
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }

  navigate(url: string) {
    this.navigateEvent.emit(url);
    this.closeMenu();
  }

  navigateFiatTransactions(url:string, tabIndex:0 | 1 | 2){
    this._fiatTabsService.setTabIndex(tabIndex);
    this.navigate(url)
    this.makeFiatActive(tabIndex)
  }

  public toggleList(list: string): void {
    this.openList[list] = !this.openList[list];
  }

  public selectLanguage(lang: string): void {
    window.localStorage.setItem('preferred_lang', lang);
    this.translateService.use(lang);
    this.openList.lang = false;
    this.currentLang = lang;
    this.previousLang = lang;
    this.languageState.setLanguage(lang);
    if (this.router.url.includes('identity-verification') && window.matchMedia('(max-width: 860px)').matches) {
      this.opened = false;
    }
  }

  public logout(): void {
    this.authService.logout();
    this.closeMenu();
    localStorage.removeItem('selected_currency');
    this.router.navigate(['/auth/login']).then();
  }

  private closeMenu() {
    this.mobileMenuService.setMenuStatus(false);
    this.closeLangSidenav();
    this.closeMenuEvent.emit(false);
  }

  public close() {
    this.opened = false;
  }

  get env() {
    return this.environment;
  }

  public swapIcon(){
    this.icon = !this.icon;
  }

  public makeFiatActive(value: 0 | 1 | 2){
    this.activeFiatTab = value;
  }

  public checkMyUrl(url:string){
    return this.router.url.includes(url);
  }

  public onMenuItemClick(event): void {
    event.preventDefault();

    this.closeMenu();
  }

  openLangSidenav() {
    this.currentLang = this.translateService.currentLang;
    this.buttonIsDisabled = this.currentLang === this.previousLang;
    this.langSidenav?.open();
  }

  closeLangSidenav() {
    this.langSidenav?.close();
  }

  confirmLanguageChange() {
    this.selectLanguage(this.currentLang);
    this.langSidenav?.close();
  }

  onLangChange(event: MatRadioChange) {
    this.buttonIsDisabled = event.value === this.previousLang;
  }

  private updateMenuLinks() {
    this.isMainDomain = !window.location.hostname.includes('trade')
      && !window.location.hostname.includes('test-')
      && !window.location.hostname.includes('localhost');

    this.isSubDomain = !window.location.hostname.includes('test-') && window.location.hostname.includes('trade');
    this.isTestSubdomain = window.location.hostname.includes('test-');
    this.menuLinks = [
      {
        path: '/crypto-card',
        routerPath: '/crypto-card',
        href: 'https://intebix.kz/crypto-card',
        label: 'CRYPTO_CARD.TITLE',
        visible: !environment.production && !this.authService.isLoggedIn,
        external: this.isMainDomain
      },
      {
        path: '/user-settings/crypto-card',
        routerPath: '/user-settings/crypto-card',
        href: 'https://trade.intebix.kz/user-settings/crypto-card',
        label: 'CRYPTO_CARD.TITLE',
        visible: !environment.production && this.authService.isLoggedIn,
        external: this.isMainDomain
      },
      {
        path: '/markets',
        routerPath: '/markets',
        href: 'https://trade.intebix.kz/markets',
        label: 'HEADER.MARKETS',
        visible: true,
        external: this.isMainDomain
      },
      {
        path: '/trades/USDT-BTC',
        routerPath: '/trades/USDT-BTC',
        href: 'https://trade.intebix.kz/trades/USDT-BTC',
        label: 'PROFILE.PROFILE.TRADING',
        visible: true,
        external: this.isMainDomain
      },
      {
        path: '/crypto-credential',
        routerPath: '/crypto-credential',
        href: 'https://intebix.kz/crypto-credential',
        label: 'Crypto Credential',
        visible: !this.authService.isLoggedIn,
        external: this.isMainDomain
      },
      {
        path: '/user-settings/crypto-credential',
        routerPath: '/user-settings/crypto-credential',
        href: 'https://trade.intebix.kz/user-settings/crypto-credential',
        label: 'Crypto Credential',
        visible: this.authService.isLoggedIn,
        external: this.isMainDomain
      },
      {
        path: '/technodom-bonuses',
        routerPath: '/technodom-bonuses',
        href: 'https://trade.intebix.kz/technodom-bonuses',
        label: 'Technodom',
        visible: !environment.production,
      },
      {
        language: true,
        visible: true
      },
      {
        path: '/auth/login',
        routerPath: '/auth/login',
        href: 'https://intebix.kz/auth/login',
        label: 'HEADER.LOGIN',
        visible: !this.authService.isLoggedIn,
        external: this.isSubDomain
      },
      {
        path: '/auth/register',
        routerPath: '/auth/register',
        href: 'https://intebix.kz/auth/register',
        label: 'HEADER.SIGNUP',
        visible: !this.authService.isLoggedIn,
        external: this.isSubDomain
      },
    ];
  }
}
