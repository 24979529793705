import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-textarea',
  templateUrl: './new-textarea.component.html',
  styleUrls: ['./new-textarea.component.scss'],
})
export class NewTextareaComponent {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;

  public onBlur(): void {
    const value = this.formControlProps.value;

    if (value) {
      this.formControlProps.setValue(this.formControlProps.value.trim());
    }
  }
}
