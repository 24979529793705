import {NgModule} from '@angular/core';
import {WheelComponent} from './wheel.component';
import {TranslateModule} from '@ngx-translate/core';
import {PrizeModalComponent} from './modals/prize-modal.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    WheelComponent,
    PrizeModalComponent
  ],
  imports: [
    TranslateModule,
    CommonModule
  ],
  exports: [WheelComponent],
})

export class WheelModule {}
