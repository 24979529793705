import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CookiesService} from './services/cookies.service';

@Injectable()
export class AppConfig {
  static init(translateService: TranslateService, cookiesService: CookiesService): () => Promise<any> {
    return ()  => {
      return Promise.resolve(true).then(() => {
        translateService.addLangs(['en', 'ru', 'zh']);
        const currentLangFromCookies = cookiesService.getCookie('preferred_lang');

        console.log('Cookies in app config', currentLangFromCookies);

        if (currentLangFromCookies) {
          console.log('if statetment');
          window.localStorage.setItem('preferred_lang', currentLangFromCookies);
          translateService.use(currentLangFromCookies);
          return;
        }

        const currentLang = window.localStorage.getItem('preferred_lang');
        if (!currentLang){
          window.localStorage.setItem('preferred_lang', 'en');
          translateService.use('en');
        } else {
          translateService.use(currentLang);
        }
      });
    };
  }
  static httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
  }
}
