import {Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DataService} from '../../services/data.service';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs/internal/Subject';
import {ProfileState} from '../../store/profile.state';
import {ThemeService} from '../../services/theme.service';
import {LanguageState} from '../../store/language.state';
import {MobileApplicationState} from '../../store/mobile-application.state';
import {MobileMenuService} from 'src/app/services/mobile-menu.service';
import {FiatTabService} from 'src/app/services/fiat-tab.service';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CookiesService} from '../../services/cookies.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isMainDomain;
  public isSubDomain;
  public isTestSubdomain;
  public isDropdownOpen = false;
  activeFiatTab: number = parseInt(localStorage.getItem('tabIndex'), 10) || 0;
  protected readonly environment = environment;
  public menuLinks: Record<string, string | boolean>[] = [
    {
      path: '/markets',
      routerPath: '/markets',
      href: 'https://trade.intebix.kz/markets',
      label: 'MARKETS.MARKET',
      visible: true
    },
    {
      path: '/trades',
      routerPath: '/trades/USDT-BTC',
      href: 'https://trade.intebix.kz/trades/USDT-BTC',
      label: 'PROFILE.PROFILE.TRADING',
      visible: true
    },
    {
      path: '/crypto-card',
      routerPath: '/crypto-card',
      href: 'https://intebix.kz/crypto-card',
      label: 'CRYPTO_CARD.TITLE',
      visible: !environment.production
    },
    {
      path: '/p2p',
      routerPath: '/p2p',
      href: 'https://intebix.kz/p2p',
      label: 'P2P.P2P',
      visible: !environment.production
    },
    {
      path: '/technodom-bonuses',
      routerPath: '/technodom-bonuses',
      href: 'https://trade.intebix.kz/technodom-bonuses',
      label: 'Technodom',
      visible: !environment.production
    },
  ];

  constructor(
    public dataService: DataService,
    public profileState: ProfileState,
    public authService: AuthService,
    public router: Router,
    private translateService: TranslateService,
    public themeService: ThemeService,
    public languageState: LanguageState,
    public mobileApplicationState: MobileApplicationState,
    public mobileMenuService: MobileMenuService,
    private _fiatTabsService: FiatTabService,
    private _matDialog: MatDialog,
    private _cookiesService: CookiesService,
  ) {
    this.isMainDomain = !window.location.hostname.includes('trade')
      && !window.location.hostname.includes('test-')
      && !window.location.hostname.includes('localhost');

    this.isSubDomain = !window.location.hostname.includes('test-') && window.location.hostname.includes('trade');

    this.isTestSubdomain = window.location.hostname.includes('test-');
  }

  public showWheelButton = false;
  public isLightTheme = false;
  public openList = { profile: false, lang: false, account: false };
  public currentLang: string;
  public reCaptcha_version = environment.name || 'prod';
  public url = environment.baseURL;
  public intervalUpdateWallets;
  public tabSubscription: Subscription;
  lastPrice: number;
  destroySubject$ = new Subject();
  @Output() menuOpenedEvent = new EventEmitter<boolean>();
  @Output() profileMenuOpenedEvent = new EventEmitter<boolean>();
  menuOpened = false;
  profileMenuOpened = false;
  @ViewChild('aside', { static: false }) aside: ElementRef;
  @ViewChild('menuspan', { static: false }) menuspan: ElementRef;

  protected readonly navigator = navigator;
  @HostListener('window:click', ['$event'])
  closeOpenedLists(event: MouseEvent): void {
    if (!event.target['classList'].contains('profile')) {
      this.openList.profile = false;
    } else if (!event.target['classList'].contains('lang')) {
      this.openList.lang = false;
    }
  }

  ngOnInit(): void {
    this.tabSubscription = this._fiatTabsService.tabIndex$.subscribe((res) => {
      this.activeFiatTab = res;
    });

    this.currentLang = this.translateService.currentLang;
  }

  ngOnDestroy() {
    if (this.authService.isLoggedIn) {
      clearInterval(this.intervalUpdateWallets);
    }
    this.destroySubject$.next();
    this.destroySubject$.complete();
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }

  navigateFiatTransactions(tabIndex: 0 | 1 | 2) {
    this._fiatTabsService.setTabIndex(tabIndex);
    this.makeFiatActive(tabIndex);
  }

  public makeFiatActive(value: 0 | 1 | 2) {
    this.activeFiatTab = value;
  }

  public checkMyUrl(url: string) {
    return this.router.url.includes(url);
  }

  public logout(): void {
    clearInterval(this.intervalUpdateWallets);
    this.closeDropdown();
    this.authService.logout();
    localStorage.removeItem('selected_currency');
    this.router.navigate(['auth/login']).then();
  }

  public toggleList(list: string): void {
    this.openList[list] = !this.openList[list];
    console.log(this.openList);
  }

  get env() {
    return this.environment;
  }

  public selectLanguage(lang: string): void {
    this._cookiesService.setCookie('preferred_lang', lang, 30);
    window.localStorage.setItem('preferred_lang', lang);
    this.translateService.use(lang);
    this.openList.lang = false;
    this.currentLang = lang;
    this.languageState.setLanguage(lang);
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  public openMenu() {
    const menuStatus = this.mobileMenuService.getMenuStatus();
    this.toggleMenu(menuStatus);
  }

  public openProfileMenu() {
    this.openMenu();
    this.toggleProfileMenu(this.profileMenuOpened);
  }

  private toggleMenu(status: boolean) {
    this.menuOpened = !status;
    this.mobileMenuService.setMenuStatus(this.menuOpened);
    this.menuOpenedEvent.emit(this.menuOpened);
    this.toggleProfileMenu(true);
  }

  private toggleProfileMenu(status: boolean) {
    this.profileMenuOpened = !status;
    this.profileMenuOpenedEvent.emit(this.profileMenuOpened);
  }

  navigateTo(url: string) {
    if (this.isMainDomain) {
      window.location.href = `https://trade.intebix.kz/${url}`;
      return;
    }

    this.router.navigate([url]).then();
  }
}
