import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import {take, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import { SecurityService } from 'src/app/pages/profile/security/services/security.service';

@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.scss'],
})
export class OtpPageComponent {
  otpArray = Array(6).fill(null);
  otpValues: string[] = new Array(6).fill('');
  timer: number = 59;
  isTimerRunning: boolean = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(
    private matDialogRef: MatDialogRef<OtpPageComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _securityService: SecurityService){
      this.startTimer();
  }

  public get title(): string {
    return this.data.title || 'Подтвердите электронную почту';
  }

  public get descriptionText(): string {
    return this.data.descriptionText || 'Введите 6-значный код, который мы выслали вам на почту';
  }

// -----------> Логика ввода
  onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

// -----------> Разрешаем только цифры
    if (!/^\d$/.test(value) && !this.data.isPhone) {
      input.value = '';
      return;
    }

    this.otpValues[index] = value;

// -----------> Переход на следующее поле
    if (index < this.otpArray.length - 1 && value) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      nextInput?.focus();
    }

// -----------> Проверка заполнения всех полей
    if (this.isOtpComplete()) {
      this.submitOtp();
    }
  }

// -----------> Функция для сброса значений поля ввода OTP
    private resetOtp(): void {
      this.otpArray.fill(null);
      this.otpValues.fill('');
    }
// -----------> Логика удаления
  onBackspace(event: KeyboardEvent, index: number): void {
    if (!this.otpValues[index] && index > 0) {
      const prevInput = (event.target as HTMLInputElement)
        .previousElementSibling as HTMLInputElement;
      prevInput?.focus();
    }
  }

// -----------> Проверить, заполнены ли все поля
  isOtpComplete(): boolean {
    return this.otpValues.every((value) => value.length === 1);
  }

// -----------> Отправить OTP
  submitOtp(): void {
    const otp = this.otpValues.join('').toUpperCase();

    if(this.data.isPhone) {
      this._securityService.verifyPhoneNumber({otp}).pipe(
        take(1)
      ).subscribe(
        (res) => {
          this.matDialogRef.close(res);
        },
        (err) => {
          this.matDialogRef.close(err);
        }
      );
      return;
    }

    this.authService.changePasswordWithToken(otp)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe({
      next: (res) => {
        this.matDialogRef.close(res);
      },
      error: (err) => {
        this.matDialogRef.close(err);
      },
    })
  }

// -----------> Логика таймера
  startTimer(): void {
    this.isTimerRunning = true;
    this.timer = 59;

    const interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(interval);
        this.isTimerRunning = false;
      }
    }, 1000);
  }

// -----------> Повторная отправка кода
    resendOtp(): void {
      if(this.timer < 1){
        this.resetOtp();
        this.startTimer();
      }
    }
 }
