import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import {interval} from 'rxjs/internal/observable/interval';
import {DataService} from '../../../services/data.service';
import {MatDialog} from '@angular/material/dialog';
import {PrizeModalComponent} from './modals/prize-modal.component';

@Component({
  selector: 'app-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss']
})
export class WheelComponent implements AfterViewInit {
  @ViewChild('container', { static: false }) container!: ElementRef;
  // sectors = [
  //   "+1%", "+500К", "+3%", "0", "+10К, 3 час", "+250К",
  //   "+5%", "+100К", "+100К, 3 час", "+50К", "+50К 3 час", "5 мин, бесконечная энергия"
  // ];
  sectors = [
    '1%', '500K', '3%', '0', '10K-3hours', '250K',
    '5%', '100K', '100K-3hours', '50K', '50K-3hours', '5min-infinity-energy'
  ];
  private timer = 0;
  public isPlayButtonDisabled = false;

  constructor(
    private _dataService: DataService,
    private _matDialog: MatDialog,
  ) {
  }

  ngAfterViewInit() {
    console.log(this.container);
  }

  doSpin() {
    this.isPlayButtonDisabled = true;
    const wheelSound = new Audio('/assets/lucky-wheel/spinning-sound.m4a');
    const prizeSound = new Audio('/assets/lucky-wheel/prize-sound.mp3');
    wheelSound.play();

    if (!this.container) {
      console.error('Элемент container не найден!');
      return;
    }

    const minTurns = 5;
    const maxTurns = 8;
    const randomDegree = 360 * (minTurns + Math.random() * (maxTurns - minTurns));
    const finalRotation = randomDegree % 360;

    const currentRotation = this.getCurrentRotation();
    const totalRotation = currentRotation + randomDegree;

    console.log(`Вращаемся на ${totalRotation} градусов`);

    // **1. Сбрасываем transition и моментально фиксируем текущее положение**
    this.container.nativeElement.style.transition = "none";
    this.container.nativeElement.style.transform = `rotate(${currentRotation}deg)`;

    // **2. Запускаем анимацию с небольшой задержкой**
    setTimeout(() => {
      this.container.nativeElement.style.transition = "transform 5s ease-out";
      this.container.nativeElement.style.transform = `rotate(${totalRotation}deg)`;
    }, 50);

    setTimeout(() => {
      // const sectorSize = 360 / this.sectors.length;
      // const selectedSector = Math.floor((360 - (totalRotation % 360)) / sectorSize) % this.sectors.length;
      const sectorSize = 360 / this.sectors.length;
      const offsetCorrection = sectorSize / 2; // Сдвигаем на половину сектора
      const adjustedRotation = (360 - (totalRotation % 360) - offsetCorrection + 360) % 360;
      const selectedSector = Math.floor(adjustedRotation / sectorSize) % this.sectors.length;


      prizeSound.play();
      this._dataService.sendPrizeWheelData({
        prize: this.sectors[selectedSector],
      }).subscribe(v => console.log(v));
      // alert(`Выпал сектор: ${this.sectors[selectedSector]}`);
      // alert(this.sectors[selectedSector] === '5min-infinity-energy');
      this._matDialog.open(PrizeModalComponent, {
        panelClass: 'wheel-dialog-class',
        backdropClass: 'wheel-dialog-backdrop',
        data: {
          prize: this.sectors[selectedSector],
        }
      });

      this.isPlayButtonDisabled = false;
    }, 5050); // Ждем завершения анимации (5 секунд + небольшой запас)
  }

  /**
   * Получаем текущий угол вращения элемента
   */
  getCurrentRotation(): number {
    const transform = window.getComputedStyle(this.container.nativeElement).transform;
    if (transform === "none") return 0;

    const matrix = new DOMMatrix(transform);
    const angle = Math.atan2(matrix.b, matrix.a) * (180 / Math.PI);

    return angle >= 0 ? angle : 360 + angle;
  }
}

