import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarketComponent } from './market/market.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ThemeSwitcherComponent } from './header/theme-switcher/theme-switcher.component';
import { DayNightToggleComponent } from './header/day-night-toggle/day-night-toggle.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SharedModule } from '../shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    OverlayModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    SharedModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatListModule,
    MatExpansionModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
  ],
  declarations: [
    MarketComponent,
    MainNavComponent,
    HeaderComponent,
    FooterComponent,
    ThemeSwitcherComponent,
    DayNightToggleComponent,
  ],
  exports: [
    MarketComponent,
    MainNavComponent,
    HeaderComponent,
    FooterComponent,
  ],
})
export class PartialsModule {}
