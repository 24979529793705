import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatepickerHeaderComponent } from '../mat-datepicker/datepicker-header/datepicker-header.component';

@Component({
  selector: 'app-mat-date-range-picker',
  templateUrl: './mat-date-range-picker.component.html',
  styleUrls: ['./mat-date-range-picker.component.scss']
})
export class MatDateRangePickerComponent implements OnInit {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() disabledProps: boolean = false;

  @Output() dateRangeSelected = new EventEmitter<{ start: string | null; end: string | null }>();


  datepickerHeaderComponent = DatepickerHeaderComponent;

  dateRangeForm = new FormGroup({
    start: new FormControl(null), // Начальная дата
    end: new FormControl(null) // Конечная дата
  });

  getSelectedDates() {
    const startDate = this.dateRangeForm.get('start')?.value;
    const endDate = this.dateRangeForm.get('end')?.value;
    // const startDate = new Date(this.dateRangeForm.get('start')?.value).toISOString().split('T')[0];
    // const endDate = new Date(this.dateRangeForm.get('end')?.value).toISOString().split('T')[0];

    console.log('Start Date:', startDate);
    console.log('End Date:', endDate);

    this.dateRangeSelected.emit({ start: startDate, end: endDate });
  }

  ngOnInit() {
    this.dateRangeForm.patchValue({
      start: new Date(),
      end: new Date()
    });
  }
}
