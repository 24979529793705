import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-input',
  templateUrl: './new-input.component.html',
  styleUrls: ['./new-input.component.scss'],
})
export class NewInputComponent implements OnInit {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;
  @Input() isPasswordProps: boolean = false;
  @Input() isInstantBuyTab: boolean;
  @Input() withMaxProps: number | null = null;
  @Input() scanBarcodeProps: boolean = false;
  @Input() inputTypeProps: string = 'text';
  @Input() isWalletsWithdrawalPage: boolean;
  @Input() valueProps: string;
  @Input() isRequired: boolean;
  @Input() dateFormat: string;

  public logoPassword: string = 'eye';

  constructor() {}

  ngOnInit(): void {
    if (this.isPasswordProps) {
      this.inputTypeProps = 'password';
    }

    if (this.valueProps) {
      this.formControlProps.setValue(this.valueProps);
    }

    if (this.dateFormat && this.formControlProps.value) {
      this.formatDateInput();
    }
  }

  private formatDateInput(): void {
    const value = this.formControlProps.value;
    if (value) {
      this.formControlProps.setValue(this.formatDate(value));
    }
  }

  public selectMax(): void {
    if (this.isWalletsWithdrawalPage) {
      this.formControlProps.setValue(this.withMaxProps.toFixed(8));
    } else {
      this.formControlProps.setValue(this.withMaxProps);
    }
  }

  public togglePassword(): void {
    this.logoPassword = this.logoPassword === 'eye' ? 'eye-off' : 'eye';
    this.inputTypeProps =
      this.inputTypeProps === 'password' ? 'text' : 'password';
  }

  public onInput(event: Event): void {
    if (this.isWalletsWithdrawalPage) {
      const inputElement = event.target as HTMLInputElement;
      let value = inputElement.value;

      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(value)) {
        value = value.slice(0, -1);
      }

      const maxDecimals = 8;
      value = this.limitDecimals(value, maxDecimals);

      if (parseFloat(value) > this.withMaxProps) {
        this.selectMax();
      } else {
        this.formControlProps.setValue(value);
      }
    } else if (this.dateFormat) {
      const inputElement = event.target as HTMLInputElement;
      const value = this.formatDate(inputElement.value);
      this.formControlProps.setValue(value);
    }
  }

  private formatDate(value: string): string {
    let rawValue = value.replace(/\D/g, '');  // Remove non-numeric characters

    if (rawValue.length > 8) {
      rawValue = rawValue.substring(0, 8);
    }

    switch (true) {
      case rawValue.length > 4:
        rawValue = `${rawValue.substring(0, 2)}/${rawValue.substring(2, 4)}/${rawValue.substring(4)}`;
        break;
      case rawValue.length > 2:
        rawValue = `${rawValue.substring(0, 2)}/${rawValue.substring(2)}`;
        break;
    }

    return rawValue;
  }



  public onBlur(): void {
    const value = this.formControlProps.value;

    if (this.inputTypeProps === 'date' && !this.isValidDate(value)) {
      this.formControlProps.setErrors({ invalidDate: true });
      return;
    }

    if (value) {
      this.formControlProps.setValue(this.formControlProps.value.trim());
    }
  }

  private isValidDate(value: any): boolean {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateRegex.test(value)) {
      return false;
    }

    const [year, month, day] = value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }

  private limitDecimals(value: string, maxDecimals: number): string {
      if (isNaN(+value)) {
        return value;
      }
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart && decimalPart.length > maxDecimals) {
        return `${integerPart}.${decimalPart.substring(0, maxDecimals)}`;
      }
      return value;
  }
}
