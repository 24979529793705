import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';

import {io} from 'socket.io-client';
import {CurrencyInterface} from '../models/currency.model';
import {ThemesModel} from '../models/themes.model';
import {MarketsShortInfoInterface} from '../models/markets-short-info.model';
import {
  IS_GEO_TURNED_ON,
  RESTRICTED_CITIES,
  RESTRICTED_COUNTRIES,
  USERS_AUTHORISED_FOR_FIAT_MODAL,
  USERS_AUTHORISED_FOR_STOP_LOSS,
} from '../app.constants';
import {RestrictedCountriesClass} from '../models/restricted-countries.class';
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from '@angular/common/http';
import {MyProfileInterface} from '../models/my-profile.model';
import {CountryClass} from '../models/country.class';
import {NavigationStart, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {FiatClass} from '../models/fiat.class';
import {TickHistoryModel} from '../models/tick-history.model';
import {CurrencyLastPriceClass} from '../models/currency-last-price.class';
import {OrderStopLimitClass} from '../models/order-stop-limit.class';
import {BCXGRedemption} from '../models/bcxg-redemption.model';
import { filter, map, shareReplay, switchMap, tap, throttle, throttleTime, scan, } from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {BalanceInterface} from '../models/balance.model';
import {concat} from 'rxjs/internal/observable/concat';
import socket from '../../assets/tradingview/charting_library/socket';
import Io from '../../assets/tradingview/charting_library/socket';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ISelectOption} from '../ui/custom-select/custom-select.component';
import {WalletBalanceInterface} from '../models/wallet-balance.model';
import {WalletFeeCurrencyInterface} from '../models/wallet-fee-currency.model';
import {fromPromise} from 'rxjs/internal-compatibility';
import {EukzSpendsDto} from './eukz-spends';
import { IMarketList } from '../interfaces/market-list.interface';
import { merge, timer } from 'rxjs';
import {MarketPair} from '../interfaces/market-pair';
import {ICompanyData} from '../pages/auth/interfaces/business-registration.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isMarketListPageSubject = new BehaviorSubject(false);
  public market = localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : 'BTC-ETH';
  public market$: BehaviorSubject<string> = new BehaviorSubject(this.market);
  public SERVER_URL: any;
  private socket: any;
  private orderBookHistory = new Subject<any>();
  private headers: Headers;
  public refreshOrders = new Subject();
  public reCaptcha_version = environment.name || 'prod';
  public currentTheme: string = ThemesModel.DEFAULT;
  public currentThemeSubject = new BehaviorSubject<string>('Theme 1');
  public authStatus$ = new BehaviorSubject<boolean>(false);

  currenciesList = new Subject<Array<CurrencyInterface>>();
  currenciesListData: Array<CurrencyInterface>;

  marketPairMinOrderAmount = new Subject<MarketsShortInfoInterface>();
  marketPairMinOrderAmountData: MarketsShortInfoInterface;

  marketPairNew = new Subject<string>();
  public refreshedStopLimitOrders = new Subject();

  exchangePrice = new Subject<string>();
  exchangePriceData: string;

  exchangeAmount = new Subject<string>();
  exchangeAmountData: string;

  loginIncomeErrorStatus = new Subject<Array<string>>();
  loginIncomeErrorStatusData: Array<string>;

  messageTypeForAlertModal = new Subject<number>();
  messageTypeForAlertModalData: number;

  isLightTheme = new Subject<boolean>();
  isLightThemeData: boolean;

  messageTypeForGlobalMessage = new Subject<number>();
  messageTypeForGlobalMessageData: number;

  totalDepositUSDT = new Subject<number>();
  totalDepositUSDTData: number;

  ticksHistory = new Subject<Array<TickHistoryModel>>();
  ticksHistoryData: Array<TickHistoryModel>;

  currencyLastPrice: CurrencyLastPriceClass;
  currencyLastPriceData = new Subject<CurrencyLastPriceClass>();

  private _lastMarketsDetails = new Map<string, any>();
  private firstCandleValueForTrade: any;

  marketsDetails$ = this.getMarketsList().pipe(
    map((markets) =>
      markets.map((v) =>
        this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName), v)
      )
    ),
    tap(
      (markets) =>
        (this._lastMarketsDetails = new Map<string, any>(
          markets.map((v) => [v.MarketName, v])
        ))
    ),
    shareReplay(1)
  );


marketsLandingDetails$ = merge(
  this.getMarketsList(),
  // Оборачиваем одиночное обновление в массив, если оно не является массивом:
  this.subscribeToMarketUpdate().pipe(map(update => Array.isArray(update) ? update : [update]))
).pipe(
  // Аккумулируем все эмиссии в один массив:
  scan((acc, curr) => {
    // Если это начальный массив – заменяем,
    // иначе обновляем существующий элемент или добавляем новый
    if (Array.isArray(curr)) {
      return curr;
    } else {
      const index = acc.findIndex(item => item.MarketName === curr.MarketName);
      if (index > -1) {
        // Обновляем существующий элемент:
        acc[index] = { ...acc[index], ...curr };
      } else {
        // Добавляем новый элемент:
        acc.push(curr);
      }
      return acc;
    }
  }, [] as MarketPair[]),
  tap((markets) => {
    this._lastMarketsDetails = new Map(markets.map(v => [v.MarketName, v]));
  }),
  shareReplay(1),
  throttleTime(1000)
);


  marketsDetailsForTrades$ = concat(
    this.getMarketsList(),
    this.subscribeToMarketUpdate(),
  ).pipe(
    map(
      (markets) => markets?.map( // TODO: markets is not array now
        (v) => this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName), v),
      )
    ),
    tap((markets) => this._lastMarketsDetails = new Map<string, any>(markets.map((v) => [v.MarketName, v]))),
    shareReplay(1)
  );

  currentMarketDetails$ = combineLatest([
    this.getMarketsList(),
    this.market$.pipe(
      switchMap(() => this.getMarketHistory())
    )
  ]).pipe(
    map(([markets, marketHistory]) => {
      const market = markets.find((m) => m.MarketName === this.market);
      return {
        ...market,
        // lastUp: (markets.lastUp || market.lastDown)
        //   ? market.lastUp : this._getLastDifferentPrice(marketHistory, market.Last) < market.Last,
        // lastDown: (markets.lastUp || market.lastDown)
        //   ? market.lastDown : this._getLastDifferentPrice(marketHistory, market.Last) > market.Last,
      };
    }),
    // tap((v) => console.log('summary delats for market', v.Last)),
    shareReplay(1),
  );

  currencyOptions$: Observable<ISelectOption<WalletBalanceInterface>[]> = this.getBalances().pipe(
    map((data: Array<WalletBalanceInterface>) => data.map(
      (v) => ({
        label: `${v.currency} (${v.currencyBase})`,
        value: v,
        iconSrc: `/assets/img/coins/${v.currency?.toLowerCase()}.png`
      })
    ))
  );
  private intervalMap: Record<string, string> = {
    '1D': '1day',
    '1W': '1week',
    '1M': '1month',
    '1Y': '1year',
    '1H': '60min',
    '1': '1min',
    '5': '5min',
    '15': '15min',
    '30': '30min',
    '2H': '2hour',
    '3H': '3hour',
    '4H': '4hour'
  };
  private orderbookSubscription: (() => void) | null = null;
  private marketSubscription: (() => void) | null = null;
  private candleSubscription: (() => void) | null = null;
  private marketAllSubscription: (() => void) | null = null;
  public marketLastPriceSubject = new BehaviorSubject<any>(null);
  public marketLastPrice$ = this.marketLastPriceSubject.asObservable();
  private currentTradeMarketRoom?: string;
  private currentOrderbookRoom?: string;
  private currentCandleRoom?: string;
  private delayTimeForMarketUpdate: number = 0;

  constructor(
    public base: BaseService,
    private http: HttpClient,
    public router: Router
  ) {
    this.SERVER_URL = environment.host;
    this.socket = io(this.SERVER_URL);
    this.headers = new Headers({'Content-Type': 'application/jsonp'});
    this.setCurrentTheme(ThemesModel.DEFAULT);

    // TODO: for updated changes for all tokens in market
    setTimeout(() => {
      this.delayTimeForMarketUpdate = 1000;
    }, 5000);
  }

  public unsubscribeAll(): void {
    this.unsubscribeFromMarket();
    this.unsubscribeFromOrderbook();
    this.unsubscribeFromCandleUpdates();
  }

  private _getLastDifferentPrice(history: Array<{rate?: number}>, price: number) {
    return history?.find((v) => v.rate !== price)?.rate || price;
  }

  private _mergeMarketField = (last: any, current: any) => {
    const mergedKeys = new Set([...Object.keys(last || {}), ...Object.keys(current)]);
    return {...Array.from(mergedKeys.values()).reduce(
      (acc, k) => ({...acc, [k]: current[k] || (last || {})[k]}), {}
    ),
      lastUp: last
        ? (+last.Last < +current.Last || (+last.lastUp && +last.Last === +current.Last))
        : +current.Last > +current.prevPrice,
      lastDown: last
        ? (+last.Last > +current.Last || (+last.lastDown && +last.Last === +current.Last))
        : +current.Last < +current.prevPrice,
      // BaseVolumeUSDT: current.MarketName.split('-')[0] === 'USDT' ? current.BaseVolume24 : current.BaseVolumeUSDT,
      BaseVolumeUSDT: current.BaseVolume24,
    };
  }

  public storePair(pair: string) {
    localStorage.setItem('bitPair', pair);
    this.market = pair;
    this.market$.next(this.market);
  }

  public getPair(): string {
    return (this.market) ? this.market : (localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : '');
  }


  // Trade market room
  public joinTradeMarketRoom(): Observable<any> {
    const pairToken = this.market.split('-').reverse().join('');
    const newRoom = `detail:${pairToken}`;

    if (this.currentTradeMarketRoom && this.currentTradeMarketRoom !== newRoom) {
      this.socket.emit('leaveRoom', { room: this.currentTradeMarketRoom });
      if (this.marketSubscription) {
        this.marketSubscription();
      }
    }

    this.currentTradeMarketRoom = newRoom;
    this.socket.emit('joinRoom', { room: newRoom });

    return new Observable<any>(observer => {
      const handler = (data: any) => {
        observer.next(data);
      };

      this.socket.on('market-update', handler);

      this.marketSubscription = () => {
        this.socket.off('market-update', handler);
      };

      return this.marketSubscription;
    });
  }

  // use this method to leave the current room (tradeMarketRoom)
  private unsubscribeFromMarket(): void {
    if (this.marketSubscription) {
      this.marketSubscription();
      this.marketSubscription = null;
    }

    if (this.currentTradeMarketRoom) {
      this.socket.emit('leaveRoom', { room: this.currentTradeMarketRoom });
      this.currentTradeMarketRoom = null;
    }
  }

  public EmitSubscribeToSummaryDeltas(): void {
    this.socket.emit('subscribe', this.getTradeMarketUpdateBody());
  }

  private getTradeMarketUpdateBody(): Record<string, string> {
    const [quoteAsset, baseAsset] = this.getPair().split('-').map(asset => asset.toLowerCase());

    return {
      symbol: `market.${baseAsset}${quoteAsset}.detail`,
      baseAsset,
      quoteAsset
    };
  }

  //

  public joinTestRoomAndSubscribeToUpdatesMasterCard() {
    if (this.socket && this.socket.connected) {
      console.warn('⚠️ WebSocket уже подключен, новое подключение не создаётся.');
      return;
    }

    this.socket.connect();
    console.log('✅ Подключение установлено:', this.socket.id);

    this.socket.emit('joinRoom', { room: 'user 123' });

    this.socket.off('connect').on('connect', () => {
      console.log('✅ Успешно подключено', this.socket.id);
    });

    this.socket.off('user 123').on('user 123', (data) => {
      console.log('Данные комнаты обновлены:', JSON.stringify(data, null, 2));
    });

    this.socket.off('error').on('error', (error) => {
      console.error('Ошибка WebSocket:', error);
    });

    this.socket.off('disconnect').on('disconnect', (reason) => {
      console.warn('❌ Соединение разорвано:', reason);

      setTimeout(() => {
        if (!this.socket.connected) {
          console.log('🔄 Переподключение...');
          this.socket.connect();
        }
      }, 3000);
    });
  }



  public joinRoomUserId(id: string) {
    const room = `user.${id}`;
    return this.socket.emit('joinRoom', { room });
  }

  public leaveRoom() {
    // console.log('leavedRoom ' + this.market);
    this.socket.emit('leaveRoom', this.market);
  }

  public leaveRoomUserId(id: string) {
    // console.log('leavedRoom ' + 'u_' + id);
    this.socket.emit('leaveRoom', '' + id);
  }

  public SubscribeMarketHistory() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeMarketHistory', data => observer.next(data));
    });
  }

  // public getTicker() {
  //   return new Observable<any>(observer => {
  //     this.socket.on('getTicker', data => {
  //       observer.next(data);
  //     });
  //   });
  // }

  // public SubscribeToExchangeDeltas() {
  //   return new Observable<any>(observer => {
  //     this.socket.on('SubscribeToExchangeDeltas', data => observer.next(data));
  //   });
  // }

  // ------------- Orderbook updates
  public SubscribeToOrderbookUpdates(): Observable<any> {
    const pair = this.getPair().split('-').reverse().join('');
    const newRoom = `depth.step0:${pair}`;

    if (this.currentOrderbookRoom && this.currentOrderbookRoom !== newRoom) {
      this.socket.emit('leaveRoom', { room: this.currentOrderbookRoom });
      if (this.orderbookSubscription) {
        this.orderbookSubscription();
      }
    }

    this.currentOrderbookRoom = newRoom;
    this.socket.emit('joinRoom', { room: newRoom });

    return new Observable<any>(observer => {
      const handler = (data: any) => {
        observer.next(data);
      };

      this.socket.on('orderbook-update', handler);

      this.orderbookSubscription = () => {
        this.socket.off('orderbook-update', handler);
      };

      return this.orderbookSubscription;
    }).pipe(throttleTime(1000));
  }

  // use this method to leave the current room (orderbook)
  private unsubscribeFromOrderbook(): void {
    if (this.orderbookSubscription) {
      this.orderbookSubscription();
      this.orderbookSubscription = null;
    }

    if (this.currentOrderbookRoom) {
      this.socket.emit('leaveRoom', { room: this.currentOrderbookRoom });
      this.currentOrderbookRoom = null;
    }
  }

  private getOrderbookUpdateBody(): Record<string, string> {
    const [quoteAsset, baseAsset] = this.getPair().split('-').map(asset => asset.toLowerCase());

    return {
      symbol: `market.${baseAsset}${quoteAsset}.depth.step0`,
      baseAsset,
      quoteAsset
    };
  }

  public EmitSubscribeToOrderbookUpdates(): void {
    this.socket.emit('subscribe', this.getOrderbookUpdateBody());
  }


  public EmitUnsubscribeToOrderbookUpdates(): void {
    this.socket.emit('unsubscribe', this.getOrderbookUpdateBody());
  }

  //


  public SubscribeToTradeUpdates() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToTradeUpdates', data => observer.next(data));
    });
  }

  public EmitSubscribeToTradeUpdates() {
    this.socket.emit('subscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public EmitUnsubscribeToTradeUpdates() {
    this.socket.emit('unsubscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public SubscribeOpenOrders() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeOpenOrders', data => observer.next(data));
    });
  }

  public subscribeToSummaryDeltas() {
    return new Observable<any>((observer) => {
      const handler = (data) => observer.next(data);
      this.socket.on('market-update', handler);

      this.marketAllSubscription?.();
      this.marketAllSubscription = () => this.socket.off('market-update', handler);

      return this.marketAllSubscription;
    }).pipe(throttleTime(1000));
  }

  public joinMarketAllRoom(): void {
    const room = 'detail:ALL';

    this.socket.emit('joinRoom', { room });
    this.socket.emit('subscribe', { symbol: 'market.all.detail', baseAsset: 'all', quoteAsset: 'all' });
  }

  public leaveMarketAllRoom(): void {
    this.socket.emit('leaveRoom', { room: 'detail:ALL' });
    this.socket.emit('unsubscribe', { symbol: 'market.all.detail', baseAsset: 'all', quoteAsset: 'all' });
  }

  public subscribeToMarketUpdate(): Observable<any> {
    return new Observable<any>((observer) => {
      const handler = (data) => observer.next(data);
      this.socket.on('market-update', handler);

      this.marketAllSubscription?.();
      this.marketAllSubscription = () => this.socket.off('market-update', handler);

      return this.marketAllSubscription;
    });
  }

  public SubscribeToUserBalances() {
    return new Observable<Array<BalanceInterface>>(observer => {
      this.socket.on('SubscribeToUserBalanceUpdates', data => observer.next(data));
    });
  }

  public SubscribeToUpdateOrders() {
    return new Observable<any>(observer => {
      this.socket.on('ud', data => observer.next(data));
    });
  }

  public SubscribeToUpdateCryptoCredentialWithdrawStatus(id: string): Observable<any> {
    return new Observable<any>(observer => {
      const handler = (data) => observer.next(data);
      this.socket.on(`user.${id}`, (data) => {
        console.log('Данные комнаты обновлены:', JSON.stringify(data, null, 2));
        observer.next(data);
      });

      return () => {
        this.socket.off(`user.${id}`, handler);
      };
    });
  }

  public SubscribeToUpdateFavs() {
    return new Observable<any>(observer => {
      this.socket.on('uf', data => observer.next(data));
    });
  }

  public SubscribeToUpdateBalances() {
    return new Observable<any>(observer => {
      this.socket.on('ub', data => observer.next(data));
    });
  }

  // ------------- Candle updates
  public SubscribeToCandleUpdates(interval): Observable<any> {
    const pair = this.getPair().split('-').reverse().join('');
    const intervalValue = this.intervalMap[interval] || interval;
    const newRoom = `kline.${intervalValue}:${pair}`;

    // in Io we have first candle value for trade
    this.fetchFirstCandleValue();

    if (this.currentCandleRoom && this.currentCandleRoom !== newRoom) {
      this.socket.emit('leaveRoom', { room: this.currentCandleRoom });
      if (this.candleSubscription) {
        this.candleSubscription();
      }
    }

    this.currentCandleRoom = newRoom;
    this.socket.emit('joinRoom', { room: newRoom });

    return new Observable<any>(observer => {
      const handler = (data: any) => {
        const candleData = data.candle;

        this.marketLastPriceSubject.next({
          Last: candleData.close,
          lastDown: candleData.low,
          lastUp: candleData.high,
        });

        observer.next(candleData);
      };

      this.socket.on('candle-update', handler);

      this.candleSubscription = () => {
        this.socket.off('candle-update', handler);
      };

      return this.candleSubscription;
    }).pipe(throttleTime(1000));
  }

  // use this method to leave the current room (candle)
  private unsubscribeFromCandleUpdates(): void {
    if (this.candleSubscription) {
      this.candleSubscription();
      this.candleSubscription = null;
    }

    if (this.currentCandleRoom) {
      this.socket.emit('leaveRoom', { room: this.currentCandleRoom });
      this.currentCandleRoom = null;
    }
  }

  private getCandleUpdateBody(data): Record<string, string> {
    const [quoteAsset, baseAsset] = this.getPair().split('-').map(asset => asset.toLowerCase());
    const intervalValue = this.intervalMap[data.candleInterval] || data.candleInterval;

    return {
      symbol: `market.${baseAsset}${quoteAsset}.kline.${intervalValue}`,
      baseAsset,
      quoteAsset
    };
  }

  public emitCandleUpdates(data): void {
    this.socket.emit('subscribe', this.getCandleUpdateBody(data));
  }

  public emitUnsubscribeCandleUpdates(data): void {
    this.socket.emit('unsubscribeToCandleUpdates', this.getCandleUpdateBody(data));
  }
//


private fetchFirstCandleValue(retries = 3, delay = 500) {
  const attemptFetch = (remainingRetries: number) => {
    setTimeout(() => {
      this.firstCandleValueForTrade = Io.firstCandleValue;

      if (this.firstCandleValueForTrade) {
        this.updateMarketLastPrice();
      } else if (remainingRetries > 0) {
        attemptFetch(remainingRetries - 1);
      }
    }, delay);
  };

  attemptFetch(retries);
}

private updateMarketLastPrice() {
  if (!this.firstCandleValueForTrade) return;

  this.marketLastPriceSubject.next({
    Last: this.firstCandleValueForTrade.close,
    lastDown: this.firstCandleValueForTrade.low,
    lastUp: this.firstCandleValueForTrade.high,
  });
}


  public downloadFile(apiUrl: string, fileName: string) {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return this.base.getBlob(apiUrl).subscribe(
      (blob) => {
        const objectUrl = window.URL.createObjectURL(blob);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      }
    );
  }

  public getMarketHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-history?market=${this.market}`);
  }

  public getFeesAndLimits(): Observable<any> {
    return this.base.get(`api/v1/user/wallets/fees`);
  }

  public getMinimalCurrencyAmount(currency: string, chain?: string): Observable<any> {
    return this.base.get(`api/v1/user/withdrawals/minimal-amount?currency=${currency}&chain=${chain}`);
  }

  public getMarketSummary(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-summary?market=${this.market}`);
  }

  public getMarketPairsShortInfoServer(): Observable<any> {
    return this.base.get(`api/v1/bitt/markets-short-info?market=` + this.getPair());
  }

  public getOrderBookHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/orderbooks-history?market=${this.market}`);
  }

  public getDataByIP(ip: string): Observable<any> {
    return this.base.getJsonP('https://ipinfo.io/json');
  }

  public getTicksHistory(): Observable<any> {
    return this.base.get('api/v1/bitt/ticks-history');
  }

  public addFreeCoinsToUserWallet(id: number): Observable<any> {
    return this.base.post(`api/v1/user/spc-bonus/${id}`);
  }

  public setCookiesAgreementStatus(id: number, isAgreed: number): Observable<any> {
    return this.base.post(`api/v1/user/cookies_agreed/${id}/${isAgreed}`);
  }

  public getFavoriteMarkets(): Observable<any> {
    return this.base.get('api/v1/favorites/list');
  }

  public getCurrencyList(): Observable<any> {
    return this.base.get('api/v1/user/currencies/list');
  }

  public withdrawCryptoCredentialMasterCard(data: any): Observable<any> {
    return this.base.post('api/v1/mastercard/withdrawal', data);
  }

  public getCurrenciesListFromServer(): void {
    this.getCurrencyList()
      .subscribe((data: Array<CurrencyInterface>) => {
        this.passSharedCurrenciesList(data);
        this.setSharedCurrenciesList(data);
      });
  }

  public getMarketMinOrderAmount() {
    this.getMarketPairsShortInfoServer()
      .subscribe(data => {
        const marketPairMinOrderAmount = data[this.getPair()];
        this.passMarketPairMinOrderAmount(marketPairMinOrderAmount);
        this.setMarketPairMinOrderAmount(marketPairMinOrderAmount);
      });
  }


  public addToFavoriteMarkets(data): Observable<any> {
    return this.base.post('api/v1/favorites', data);
  }

  public removeFromFavoriteMarkets(data): Observable<any> {
    return this.base.delete('api/v1/favorites', data);
  }

  public getBalances(): Observable<any[]> {
    return this.getWallets().pipe(map(
      (data) => data.map((v) => ({...v, valueUSDT: v.currency === 'USDT' ? v.balance : v.valueUSDT}))
    ));
  }

  public buy(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/buy', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public sendImagesKYC(type: string, document: any): Promise<any> {
    const input = new FormData();
    let url = '';
    switch (type) {
      case 'passport':
        url = '/kyc-passport';
        break;
      case 'selfie':
        url = '/kyc-selfie';
        break;
    }

    input.set('type', type);
    input.set('document', document);
    return this.base.postMultipartFormData(`api/v1/user${url}`, input);
  }

  // public sendPDFtoServer(url: string, body: any): Observable<any> {
  //   const headers: Headers = new Headers();
  //   this.token = localStorage.getItem('token');
  //
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Authorization', 'Bearer ' + this.token);
  //   // headers.append('Accept', 'application/json');
  //
  //   return this.http.post(url, body, {headers: headers})
  //     .map((res: Response) => <Object[]>res.json());
  // }

  public getWallets(): Observable<any[]> {
    return this.base.get('api/v1/user/wallets');
  }

  public getCryptoCardTransactionsHistory(body: any): Observable<any[]> {
    return this.base.post('api/v1/cryptocard/statement', body);
  }

  public minWithdrawalSum(currency: string): Observable<any> {
    return this.base.get(`api/v1/user/withdrawals/minimal-amount?currency=${currency}`);
  }

  public sendPrizeWheelData(body: any): Observable<any> {
    return this.base.post('api/v1/promocode/prize', body);
  }

  public getImagesKYC(): Observable<any> {
    return this.base.get('api/v1/user/kyc-urls');
  }

  public getDepositsWithdrawals(): Observable<any> {
    return this.base.get('api/v1/user/deposits-withdrawals');
  }

  public getDeposits(): Observable<any[]> {
    return this.base.get('api/v1/user/deposits');
  }

  public getWithdrawals(): Observable<any[]> {
    return this.base.get('api/v1/user/withdrawals');
  }

  public sell(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/sell', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public updateOrderBookData(data) {
    this.orderBookHistory.next(data);
  }

  public getOrderBookData() {
    return this.orderBookHistory.asObservable();
  }

  public registerMastercardAccountByNickname(nickname: string) {
    return this.base.post('api/v1/mastercard/account', {
      accountAlias: nickname,
    });
  }

  public registerMastercardAccountByEmail(email: string) {
    return this.base.post('api/v1/mastercard/account/email', {
      accountAlias: email,
    });
  }

  public getCryptoCredentialWallets() {
    return this.base.get('api/v1/mastercard/account/wallets');
  }

  public checkMastercardAccountStatus(nickname: string) {
    return this.base.post('api/v1/mastercard/account/search', {
      accountAlias: nickname,
    });
  }

  public getTransactions(): Observable<any> {
    return this.base.get('api/v1/bitt/withdrawal-deposit');
  }

  public getWalletFeeCurrency(currency: string, chain?: string): Observable<{res: WalletFeeCurrencyInterface}> {
    return this.base.get('api/v1/user/wallets/fee?currency=' + currency + `&chain=${chain}`);
  }

  public getAllCurrenciesFees(): Observable<any> {
    return this.base.get('api/v1/user/wallets/all-currencies');
  }

  public getAllCurrencieHealth(): Observable<any> {
    return this.base.get('api/v1/bitt/currencies-health');
  }

  public getBlockedMarkets(): Observable<any> {
    return this.base.get('api/v1/settings/blocked-markets');
  }

  public getServerCountries(): Observable<any> {
    return this.base.get('api/v1/settings/countries');
  }

  public getServerBlackListCountries(): Observable<any> {
    return this.base.get('api/v1/settings/black-list-countries');
  }

  public withdrawal(data): Observable<any> {
    // return this.base.post('api/v1/order/withdrawal', data);
    return this.base.post('api/v1/user/withdrawal', data);
  }

  public updateProfile(data): Observable<any> {
    return this.base.patch('api/v1/user/me', data);
  }

  public getMarketsList(): Observable<any> {
    return this.base.get('api/v1/settings/markets');
  }

  public getFreeCoinsStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/spc-bonus/${userId}`);
  }

  public postRegistrationBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/reg-bonus/${userId}`);
  }

  public postDepositBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/dep-bonus/${userId}`);
  }

  public postKycBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/kyc-bonus/${userId}`);
  }

  // public postUpdateWallets(userId: number): Observable<any> {
  //   return this.base.post(`api/v1/user/update-wallets/${userId}`);
  // }

  public postUpdateWithdrawalStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/check-withdrawal-status/${userId}`);
  }
  //
  // public postCheckPendingWalletsStatus(userId: number): Observable<any> {
  //   return this.base.get(`api/v1/user/check-pending-wallets/${userId}`);
  // }

  getUserProfile(): Observable<any> {
    return this.base.get('api/v1/user/me');
  }

  getExchangeRates(coinShortName: string, convertedCurrency: string): Observable<any> {
    return this.base.get(`api/v1/home/rates?from=${coinShortName}&to=${convertedCurrency}`);
  }

  getMasterCardAccountStatus(): Observable<any>  {
    return this.base.get('api/v1/mastercard/account/profile');
  }

  getUserKycStatus(): Observable<any> {
    return this.base.get('api/v1/user/kyc-status');
  }

  getUserLogs(): Observable<any> {
    return this.base.get('api/v1/user/logs');
  }

  getIpWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/white-list');
  }

  addToIpWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/white-list', data);
  }

  removeFromIpWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/white-list', data);
  }

  getWithdrawalWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/withdrawal-white-list');
  }

  addToWithdrawalWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/withdrawal-white-list', data);
  }

  getLimitAndFees(): Observable<any> {
    return this.base.get('api/v1/settings/fees-and-limits');
}

  removeFromWithdrawalWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/withdrawal-white-list', data);
  }

  // trade temporary
  getOrderHistory(showOtherPairs: string): Observable<any> {
    let path = 'api/v1/order/history?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }

  getOpenOrders(showOtherPairs: string): Observable<any> {
    // let path = 'api/v1/order/open?market=';
    let path = 'api/v1/order/open?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }

  getOpenStopLimitOrders(userId: number): Observable<any> {
    return of([]);
    // return this.base.get(`api/v1/order/getUserStopLimitOrders/${userId}`);
  }

  exchangeBuy(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeBuy', data);
  }

  exchangeBuyMarket(quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeBuyMarket', data);
  }

  exchangeStopLimit(order: OrderStopLimitClass): Observable<any> {
    return this.base.post('api/v1/order/create-stop-limit', order);
  }

  exchangeSell(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeSell', data);
  }

  exchangeSellMarket(quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeSellMarket', data);
  }

  exchangeSellLadderLimit(takeProfit: number, stopLoss: number, quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      takeProfit: takeProfit,
      stopLoss: stopLoss,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeSellLadderLimit', data);
  }

  cancelOpenOrder(order): Observable<any> {
    return this.base.delete('api/v1/order/cancel/' + order, {});
  }

  cancelOpenOrders(orderIds: number[]): Observable<any> {
    return this.base.post('api/v1/order/cancel-all', orderIds);
  }

  cancelOpenStopLimitOrder(orderId): Observable<any> {
    return this.base.delete(`api/v1/order/cancelStopLimitOrder/${orderId}`, {});
  }

  deleteStopLimitOrder(orderId, body): Observable<any> {
    return this.base.patch(`api/v1/order/updateStopLimitOrder/${orderId}`, body);
  }

  cancelWithdrawal(withdrawal): Observable<any> {
    // user/cancel-withdrawal/{withdrawalId}
    return this.base.delete(`api/v1/user/cancel-withdrawal/${withdrawal}`, {});
  }

  public getWallet(currency): Observable<any> {
    return this.base.post('api/v1/user/wallet', {currency});
  }

  public initiatePoli(data: FiatClass): Observable<any> {
    return this.base.post('api/v1/user/poli/initiateTransaction', data);
  }

  // public getCurrencyList(): Array<Object> {
  //   return [
  //     {name: 'BTC', fullName: 'Bitcoin'},
  //     {name: 'BCH', fullName: 'Bitcoin Cash'},
  //     {name: 'XRP', fullName: 'Ripple'},
  //     {name: 'ZCL', fullName: 'ZClassic'},
  //     {name: 'ADA', fullName: 'Cardano'},
  //     {name: 'ETH', fullName: 'Ethereum'},
  //     {name: 'ETC', fullName: 'Ethereum Classic'},
  //     {name: 'MFT', fullName: 'Mainframe'},
  //     {name: 'XVG', fullName: 'Verge'},
  //     {name: 'XLM', fullName: 'Stellar'},
  //     {name: 'XMR', fullName: 'Monero'},
  //     {name: 'UP', fullName: 'UpToken'},
  //     {name: 'CMCT', fullName: 'Crowd Machine'},
  //     {name: 'PAY', fullName: 'TenX'},
  //     {name: 'TRX', fullName: 'TRON'},
  //     {name: 'POLY', fullName: 'Polymath'},
  //     {name: 'SC', fullName: 'Siacoin'},
  //     {name: 'LTC', fullName: 'Litecoin'},
  //     {name: 'SWT', fullName: 'Swarm City'},
  //     {name: 'STRAT', fullName: 'Stratis'},
  //     {name: 'RDD', fullName: 'ReddCoin'},
  //     {name: 'REP', fullName: 'Augur'},
  //     {name: 'DASH', fullName: 'Dash'},
  //     {name: 'NLG', fullName: 'Gulden'},
  //     {name: 'RFR', fullName: 'Refereum'},
  //     {name: 'DOGE', fullName: 'Dogecoin'},
  //     {name: 'GBYTE', fullName: 'Byteball Bytes'},
  //     {name: 'ARDR', fullName: 'Ardor'},
  //   ].sort((a, b) => {
  //     if (a.name < b.name ) return -1;
  //     if (a.name > b.name ) return 1;
  //     return 0;
  //   });
  // }

  passSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesList.next(data);
  }

  setSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesListData = data;
  }

  getSharedCurrenciesList(): Array<CurrencyInterface> {
    return this.currenciesListData;
  }

  getSharedCurrenciesListEmitter(): Observable<Array<CurrencyInterface>> {
    return this.currenciesList.asObservable();
  }

  passMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmount.next(data);
  }

  setMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmountData = data;
  }

  getMarketPairMinOrderAmount(): MarketsShortInfoInterface {
    return this.marketPairMinOrderAmountData;
  }

  getMarketPairMinOrderAmountEmitter(): Observable<MarketsShortInfoInterface> {
    return this.marketPairMinOrderAmount.asObservable();
  }

  passMarketPairNew(data: string) {
    this.marketPairNew.next(data);
  }

  getMarketPairNewEmitter(): Observable<string> {
    return this.marketPairNew.asObservable();
  }

  passExchangeAmount(data: string) {
    this.exchangeAmount.next(data);
  }

  setExchangeAmount(data: string) {
    this.exchangeAmountData = data;
  }

  getExchangeAmount(): string {
    return this.exchangeAmountData;
  }

  getExchangeAmountEmitter(): Observable<string> {
    return this.exchangeAmount.asObservable();
  }

  passExchangePrice(data: string) {
    this.exchangePrice.next(data);
  }

  setExchangePrice(data: string) {
    this.exchangePriceData = data;
  }

  getExchangePrice(): string {
    return this.exchangePriceData;
  }

  getExchangePriceEmitter(): Observable<string> {
    return this.exchangePrice.asObservable();
  }

  passLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatus.next(data);
  }

  setLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatusData = data;
  }

  getLoginIncomeErrorStatus(): Array<string> {
    return this.loginIncomeErrorStatusData;
  }

  getLoginIncomeErrorStatusEmitter(): Observable<Array<string>> {
    return this.loginIncomeErrorStatus.asObservable();
  }

  passMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModal.next(data);
  }

  setMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModalData = data;
  }

  getMessageTypeForAlertModal(): number {
    return this.messageTypeForAlertModalData;
  }

  getMessageTypeForAlertModalEmitter(): Observable<number> {
    return this.messageTypeForAlertModal.asObservable();
  }

  passIsLightTheme(data: boolean) {
    this.isLightTheme.next(data);
  }

  // setIsLightTheme(data: boolean) {
  //   this.isLightThemeData = data;
  // }

  // getIsLightTheme(): boolean {
  //   return this.isLightThemeData;
  // }

  getIsLightThemeEmitter(): Observable<boolean> {
    return this.isLightTheme.asObservable();
  }

  setCurrentTheme(theme) {
    const currentTheme = localStorage.getItem('currentTheme');
    if (currentTheme) {
      this.currentTheme = currentTheme;
    } else {
      this.currentTheme = theme;
    }
  }

  passMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessage.next(data);
  }

  setMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessageData = data;
  }

  getMessageTypeForGlobalMessage(): number {
    return this.messageTypeForGlobalMessageData;
  }

  getMessageTypeGlobalMessageEmitter(): Observable<number> {
    return this.messageTypeForGlobalMessage.asObservable();
  }

  passTotalDepositUSDT(data: number) {
    this.totalDepositUSDT.next(data);
  }

  setTotalDepositUSDT(data: number) {
    this.totalDepositUSDTData = data;
  }

  getTotalDepositUSDT(): number {
    return this.totalDepositUSDTData;
  }

  getTotalDepositUSDTEmitter(): Observable<number> {
    return this.totalDepositUSDT.asObservable();
  }

  passTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistory.next(data);
  }

  setTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistoryData = data;
  }

  getTickHistory(): Array<TickHistoryModel> {
    return this.ticksHistoryData;
  }

  getTickHistoryEmitter(): Observable<Array<TickHistoryModel>> {
    return this.ticksHistory.asObservable();
  }

  setCurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.currencyLastPrice = data;
  }

  passCurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.currencyLastPriceData.next(data);
  }

  getСurrencyLastPrice(): CurrencyLastPriceClass {
    return this.currencyLastPrice;
  }

  getСurrencyLastPriceEmitter(): Observable<CurrencyLastPriceClass> {
    return this.currencyLastPriceData.asObservable();
  }

  public convertExpToNumber(_value: number): string {
    const value = '' + _value;
    const newValue = ((value.indexOf('e') >= 0) || (value.indexOf('E') >= 0)) ? this.convertExpToString(_value) : value;

    const newValueArr = newValue.split('.');
    let decimal = newValueArr[1] ? newValueArr[1] : '00000000';

    if (newValueArr && newValueArr[1] && newValueArr[1].length) {
      switch (newValueArr[1].length) {
        case 1:
          decimal = newValueArr[1] + '0000000';
          break;
        case 2:
          decimal = newValueArr[1] + '000000';
          break;
        case 3:
          decimal = newValueArr[1] + '00000';
          break;
        case 4:
          decimal = newValueArr[1] + '0000';
          break;
        case 5:
          decimal = newValueArr[1] + '000';
          break;
        case 6:
          decimal = newValueArr[1] + '00';
          break;
        case 7:
          decimal = newValueArr[1] + '0';
          break;
      }
    } else {
      // console.log(_value);
    }

    return newValueArr[0] + '.' + decimal;
  }

  public removeCountryfromIsoNames(countryIso: string, countries: Array<string>): Array<string> {
    if (countries && countries.length) {
      return countries.filter(country => country !== countryIso);
    } else {
      return countries;
    }
  }

  public convertExpToString(expNumber: number): string {
    const data = String(expNumber).split(/[eE]/);
    if (data.length === 1) {
      return data[0];
    }
    let z = '';
    const sign = expNumber < 0 ? '-' : '';
    const str = data[0].replace('.', '');
    let mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) {
        z += '0';
      }
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) {
      z += '0';
    }
    return str + z;
  }

  public getEmailLowercase(data: string): string {
    const login = data.split('@')[0];
    const host = data.split('@')[1];
    return login.toLocaleLowerCase() + '@' + host;
  }

  getSumsubAccessToken(userId, ttlInSecs): Observable<any> {
    return this.base.get(`api/v1/sumsub/access-token?userId=${userId}&ttlInSecs=${ttlInSecs}`);
  }

  getMyRedemptions(): Observable<ReadonlyArray<BCXGRedemption>> {
    return this.base.get(`api/v1/bcxg/my-redemptions`);
  }

  cancelRedemptionRequestByUser(redemptionId: number): Observable<any> {
    return this.base.post(`api/v1/bcxg/cancel-redemption-by-user`, {redemptionId});
  }

  getGoldPricePerOunceUSD(): Observable<number> {
    return this.base.get(`api/v1/bcxg/gold-price-per-ounce-usd`);
  }

  getAccountBCXGValue(): Observable<number> {
    return this.base.get(`api/v1/bcxg/account-bcxg`);
  }

  createRedemption(bcxgRedemption: BCXGRedemption): Observable<any> {
    return this.base.post(`api/v1/bcxg/create-redemption`, bcxgRedemption);
  }

  uploadDocument(formData: FormData) {
    return fromPromise(this.base.postMultipartFormData(`api/v1/user/document-upload`, formData));
  }

  saveDocument(data: any) {
    return this.base.post('api/v1/user/document-send', data);
  }

  checkTwoFa(code: string): Observable<boolean> {
    return this.base.post('check-totp', { code })
      .pipe(map(result => result.is));
  }

  getExchangeRate(type: 'BUY' | 'SELL'): Observable<any> {
    return this.base.get(`exchange-rate-price/${type}`);
  }

  getAllHistory(): Observable<any> {
    return this.base.get(`api/v1/order/overall-history`);
  }

  checkTDBonuses(amount: number): Observable<any> {
    return this.base.post(`api/v1/tdbonuses/check`, { amount });
  }

  payForTDBonuses(id: string, amount: string): Observable<any> {
    return this.base.post(`api/v1/tdbonuses/pay`, { id, amount });
  }

  getFearGreedIndex(): Observable<any> {
    return this.http.get('https://api.alternative.me/fng/?limit=1');
  }

  companyData(data: ICompanyData) {
    return this.base.patch('api/v1/user/business', data);
  }

  uploadFile(data) {
    return fromPromise(this.base.postMultipartFormData('upload-files', data));
  }

  checkFilesUploaded() {
    const token = JSON.parse(sessionStorage.getItem('jwtToken')) || JSON.parse(localStorage.getItem('jwtToken'));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: (token && token.accessToken) ? token.accessToken : ''
      })
    };
    return this.base.get('check-files-uploaded', httpOptions);
  }
}

