import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-error-text',
  templateUrl: './input-error-text.component.html',
  styleUrls: ['./input-error-text.component.scss']
})
export class InputErrorTextComponent implements OnInit {

  @Input() control!: AbstractControl | null;
  @Input() errorRequiredText: string;
  @Input() errorEmailText: string;
  @Input() errorPatternText: string;
  constructor() { }

  ngOnInit(): void {
  }

  get errorMessage(): string | null {
    if (!this.control || !this.control.errors || !this.control.touched) {
      return null;
    }

    if (this.control.hasError('required')) {
      return this.errorRequiredText;
    }
    if (this.control.hasError('email')) {
      return this.errorEmailText;
    }
    if (this.control.hasError('pattern')) {
      return this.errorPatternText;
    }

    return null;
  }
}
