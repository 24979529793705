import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertComponent } from './alert.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AlertComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  exports: [AlertComponent],
})
export class AlertModule {}
